<template>
    <div class="settingsList">
        <div class="settingsContent">
            <b-button variant="primary" block @click="Logout()">登出</b-button>
        </div>
    </div>
</template>

<script>
    // import ModalWindow from "@vuesence/modal-window"
    import $ from 'jquery'
    import Order from './order.vue';
    import Stomp from 'stompjs'
    import Store from '../store/index.js'
    import { Window } from '@progress/kendo-vue-dialogs';
    import { Button } from '@progress/kendo-vue-buttons';
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

    import { Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect } from '@/store/RabbitMQ.js';
    import { Start_SignalR_Connect } from '@/store/SignalRHub.js'

    import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
    import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
    import axios from 'axios';

    export default {
        components: {

        },
        data: function () {
            return {

            }
        },
        created() {

        },
        destroyed() {

        },
        mounted() {

        },
        methods: {
            Logout() {
                this.visibleDialog = !this.visibleDialog;
                localStorage.setItem("access_token", "");
                localStorage.setItem("loglevel:webpack-dev-server", "");
                localStorage.setItem("isAuthenticated", "");
                location.reload();
            },
        },
    };
</script>
<style scoped>
    .settingsContent {
        margin-top: 20px;
    }
</style>