/* eslint-disable */
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import VeeValidate, { validate } from 'vee-validate'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
/*--<驗證設定>--start*/
import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate';
import  VeeValidate from 'vee-validate';
import zhTW  from 'vee-validate/dist/locale/zh_TW';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axiosSetup from "./helpers/axios.js";
import '@progress/kendo-ui'
import { Scheduler } from '@progress/kendo-scheduler-vue-wrapper'
import { SchedulerResource } from '@progress/kendo-scheduler-vue-wrapper'
import { SchedulerView } from '@progress/kendo-scheduler-vue-wrapper'
import { SchedulerInstaller } from '@progress/kendo-scheduler-vue-wrapper'

import Highcharts from 'highcharts';
//import Highcharts from './assets/highcharts/code/highcharts-more.src.js';
import Stock from 'highcharts/modules/stock';
//import Stock from './assets/highcharts/code/modules/stock.src.js';
import DarkUnica from 'highcharts/themes/dark-unica';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import DragPanes from 'highcharts/modules/drag-panes';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import PriceIndicators from 'highcharts/modules/price-indicator';
import FullScreen from 'highcharts/modules/full-screen';
import StockTools from 'highcharts/modules/stock-tools';
import HighchartsVue from "highcharts-vue";
import splitPane from 'vue-splitpane'
Vue.component('split-pane', splitPane);
Vue.use(HighchartsVue)
Stock(Highcharts);
DarkUnica(Highcharts);

IndicatorsAll(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicators(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

// import signalr from './utils/signalR'
// Vue.use(signalr);
axiosSetup();
Vue.use(ElementUI);
Vue.component('Loading', Loading);
Vue.use(SchedulerInstaller);
//localize('zh_TW', zhTW)

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//Vue.config.productionTip = false;
// Object.keys(rules).forEach((rule) => {
//   extend(rule, rules[rule]);
// });
Vue.use(VueAxios,axios);
axios.defaults.witCredentials = true;
Vue.component('ValidationObserver', ValidationObserver)
/*--<驗證設定>--end*/
//import { required } from "vee-validate/dist/rules";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$httpPath_py = "https://m.future.ctinfo.com.tw/future_pyapi";
Vue.prototype.$httpPath = "https://m.future.ctinfo.com.tw/back/api";
Vue.prototype.$httpPath_signalR = "https://m.future.ctinfo.com.tw/back";
Vue.prototype.$httpPath_front = "https://m.future.ctinfo.com.tw";

// Vue.prototype.$httpPath = "https://localhost:44344/api";
// Vue.prototype.$httpPath_signalR = "https://localhost:44344";
// Vue.prototype.$httpPath_front = "https://localhost";

// global variable
Vue.prototype.$LEFTK_HS = null;
//
(function(H) {
  H.wrap(H.Popup.prototype.indicators, 'getNameType', function(proceed, series, type) {
    var options = series.options,
      seriesTypes = H.seriesTypes,
      
      // add mode
      
      seriesName = seriesTypes[type] &&
      seriesTypes[type].prototype.nameBase ||type.toUpperCase(),
      seriesType = type
    // edit
    if (options && options.type) {
      seriesType = series.options.type;
      seriesName = series.name;
    }
    //const translatedSeriesName = seriesName === 'SMA' ? 'translated SMA' : seriesName;
    if(seriesName === 'SMA')
    {
      return {
        name: '移動平均線 SMA',
        type: seriesType
      };      
    }
    else if(seriesName === 'Accumulation/Distribution')
    {
      return {
        name: '累積/派發線 A/D',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 AO',
        type: seriesType
      };
    }    
    else if(seriesName === 'Aroon')
    {
      return {
        name: '阿隆指標 Aroon',
        type: seriesType
      };
    }
    else if(seriesName === 'Aroon Oscillator')
    {
      return {
        name: '阿隆震盪指標',
        type: seriesType
      };
    }
    else if(seriesName === 'ATR')
    {
      return {
        name: '平均真實波幅 ATR',
        type: seriesType
      };
    }
    else if(seriesName === 'BB')
    {
      return {
        name: '布林帶 BB',
        type: seriesType
      };
    }
    else if(seriesName === 'CCI')
    {
      return {
        name: '順勢指標 CCI',
        type: seriesType
      };
    }
    else if(seriesName === 'CMF')
    {
      return {
        name: '蔡金資金流量 CMF',
        type: seriesType
      };
    }
    else if(seriesName === 'DPO')
    {
      return {
        name: '區間震盪指標DPO',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 A/D',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 A/D',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 A/D',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 A/D',
        type: seriesType
      };
    }
    else if(seriesName === 'AO')
    {
      return {
        name: '動量震盪指標 A/D',
        type: seriesType
      };
    }        
    else if(seriesName === 'Linear Regression Indicator')
    {
      return {
        name: '線性回歸指標 Linear Regression Indicator',
        type: seriesType
      };
    }       
    else if(seriesName === 'Linear Regression Intercept Indicator')
    {
      return {
        name: '線性回歸截距指標 Linear Regression Intercept Indicator',
        type: seriesType
      };
    }
    else if(seriesName === 'Linear Regression Slope Indicator')
    {
      return {
        name: '線性回歸斜率指標 Linear Regression Slope Indicator',
        type: seriesType
      };
    }    
    else if(seriesName === 'Linear Regression Angle Indicator')
    {
      return {
        name: '線性回歸角度指標 Linear Regression Angle Indicator',
        type: seriesType
      };
    }                 
    else if(seriesName === 'DMI')
    {
      return {
        name: '趨向指標 DMI',
        type: seriesType
      };
    }                
    else if(seriesName === 'EMA')
    {
      return {
        name: '指數平滑移動平均線 EMA',
        type: seriesType
      };
    }   
    else if(seriesName === 'Chaikin Osc')
    {
      return {
        name: '蔡金擺動指標 Chaikin Osc',
        type: seriesType
      };
    }                   
    else if(seriesName === 'Chaikin Osc')
    {
      return {
        name: '蔡金擺動指標 Chaikin Osc',
        type: seriesType
      };
    } 
    else if(seriesName === 'CMO')
    {
      return {
        name: '錢德動量擺動指標 CMO',
        type: seriesType
      };
    } 
    else if(seriesName === 'DEMA')
    {
      return {
        name: '雙指數移動平均線 DEMA',
        type: seriesType
      };
    } 
    else if(seriesName === 'TEMA')
    {
      return {
        name: '三重指數移動均線 TEMA',
        type: seriesType
      };
    } 
    else if(seriesName === 'TRIX')
    {
      return {
        name: '三重指數平滑移動平均指標 TRIX',
        type: seriesType
      };
    } 
    else if(seriesName === 'APO')
    {
      return {
        name: '絕對價格震盪指標 APO',
        type: seriesType
      };
    } 
    else if(seriesName === 'IKH')
    {
      return {
        name: '一目平衡表技術指標 IKH',
        type: seriesType
      };
    } 
    else if(seriesName === 'Keltner Channels')
    {
      return {
        name: '凱勒通道 Keltner Channels',
        type: seriesType
      };
    } 
    else if(seriesName === 'Klinger')
    {
      return {
        name: '克林格指標 Klinger',
        type: seriesType
      };
    } 
    else if(seriesName === 'MACD')
    {
      return {
        name: '平滑異同移動平均線指標 MACD',
        type: seriesType
      };
    } 

    else if(seriesName === 'Money Flow Index')
    {
      return {
        name: '資金流向指標 Money Flow Index',
        type: seriesType
      };
    } 
    else if(seriesName === 'Momentum')
    {
      return {
        name: '動量指標 Momentum',
        type: seriesType
      };
    } 
    else if(seriesName === 'NATR')
    {
      return {
        name: '歸一化波動幅度均值 NATR',
        type: seriesType
      };
    } 
    else if(seriesName === 'OBV')
    {
      return {
        name: '能量潮指標 OBV',
        type: seriesType
      };
    }             
    else if(seriesName === 'Pivot Points')
    {
      return {
        name: '樞軸點 Pivot Points',
        type: seriesType
      };
    } 
    else if(seriesName === 'PPO')
    {
      return {
        name: '價格震盪指標 PPO',
        type: seriesType
      };
    } 
    else if(seriesName === 'Price Channel')
    {
      return {
        name: '價格通道指標 Price Channel',
        type: seriesType
      };
    } 
    else if(seriesName === 'Price envelopes')
    {
      return {
        name: '包絡線指標 Price envelopes',
        type: seriesType
      };
    } 
    else if(seriesName === 'PSAR')
    {
      return {
        name: '拋物線轉向指標 PSAR',
        type: seriesType
      };
    } 
    else if(seriesName === 'Rate of Change')
    {
      return {
        name: '變動率指標 Rate of Change',
        type: seriesType
      };
    } 
    else if(seriesName === 'RSI')
    {
      return {
        name: '相對強弱指標 RSI',
        type: seriesType
      };
    } 
    else if(seriesName === 'Stochastic')
    {
      return {
        name: '隨機指標 Stochastic',
        type: seriesType
      };
    }        
    else if(seriesName === 'Slow Stochastic')
    {
      return {
        name: '慢速隨機指標 Slow Stochastic',
        type: seriesType
      };
    } 
    else if(seriesName === 'Supertrend')
    {
      return {
        name: '超級趨勢指標 Supertrend',
        type: seriesType
      };
    } 
    else if(seriesName === 'OBV')
    {
      return {
        name: '能量潮指標 OBV',
        type: seriesType
      };
    } 
    else if(seriesName === 'Volume by Price')
    {
      return {
        name: '價量趨勢 Volume by Price',
        type: seriesType
      };
    } 
    else if(seriesName === 'VWAP')
    {
      return {
        name: '成交量加權平均價 VWAP',
        type: seriesType
      };
    } 
    else if(seriesName === 'Williams %R')
    {
      return {
        name: '威廉指標 Williams %R',
        type: seriesType
      };
    } 
    else if(seriesName === 'WMA')
    {
      return {
        name: '加權移動平均線 WMA',
        type: seriesType
      };
    } 
    else if(seriesName === 'Zig Zag')
    {
      return {
        name: '轉折指標 Zig Zag',
        type: seriesType
      };
    }                             
    else if(seriesName === 'Acceleration Bands')
    {
      return {
        name: '加速通道 Acceleration Bands',
        type: seriesType
      };
    } 
    else if(seriesName === 'Trendline')
    {
      return {
        name: '趨勢線 Trendline',
        type: seriesType
      };
    } 
    else if(seriesName === 'Disparity Index')
    {
      return {
        name: '差異指數 Disparity Index',
        type: seriesType
      };
    }                                       
    return {
      name: seriesName,
      type: seriesType
    };
  });
}(Highcharts))

Highcharts.setOptions({
  lang: {
          contextButtonTitle:"選單",
          decimalPoint:".",    
          drillUpText:"返回 {series.name}",
          noData:"沒有數據",
          numericSymbols: [ "千" , "兆" , "G" , "T" , "P" , "E"],
          printChart:"列印圖表",
          resetZoom:"恢復縮放",
          resetZoomTitle:"恢復圖表",    
          thousandsSep:",",      
          loading: '加載中...',
          months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月'],
          shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月'],
          weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
          exportButtonTitle: '匯出',
          printButtonTitle: '列印',
          rangeSelectorFrom: '從',
          rangeSelectorTo: '到',
          rangeSelectorZoom: "縮放",
          downloadPNG: '下載PNG',
          downloadJPEG: '下載JPEG',
          downloadPDF: '下載PDF',
          downloadSVG: '下載SVG',
          stockTools: {
            gui: {
              indicators: '技術指標',
              simpleShapes: '標示',
              lines: '線',
              crookedLines: '折線',
              flags: '旗標',
              toggleAnnotations: '顯示/關閉注釋',
              fullScreen: '全螢幕',
              measure:'測量',
                // Measures:
              measureXY: '測量XY',
              measureX: '測量X',
              measureY: '測量Y',              
              advanced:'黃金分割線',
              fibonacci: '黃金分割線',
              fibonacciTimeZones: '時間軸黃金分割線',
              pitchfork: '分叉線',
              parallelChannel: '平行線',
              timeCycles: '時間環',
              verticalLabels:'垂直標籤',
              currentPriceIndicator:'目前數據',
              zoomChange:'縮放',
              zoomX:'X縮放',
              zoomY:'X縮放',
              zoomXY:'XY縮放',
              typeChange:'圖形格式',
              typeOHLC:'美國線OHLC',
              typeLine:'折線',
              typeCandlestick:'K線圖',
              typeHollowCandlestick:'空心K線圖',
              typeHLC:'幅度分析指標HLC',
              typeHeikinAshi:'裁縫線',
              flagCirclepin:'圓形旗標',
              flagDiamondpin:'鑽石旗標',
              flagSquarepin:'方形旗標',
              flagSimplepin:'簡單旗標',
              label:'文字標示',
              circle:'圓形',
              ellipse: '橢圓形',
              rectangle: '長方形',
              segment: '線段',
              arrowSegment: '箭頭線段',
              ray: '單向直線',
              arrowRay: '單向箭頭直線',
              line: '雙向直線',
              arrowInfinityLine: '雙向箭頭直線',
              horizontalLine: 'Horizontal line',
              verticalLine: 'Vertical line',
              infinityLine: 'Infinity line',              
              crooked3: '2折線',
              elliott3: '艾略特3折線',
              crooked5: '4折線',
              elliott5: '艾略特5折線',
              // Counters:
              verticalCounter: '垂直線計數器',
              verticalLabel: '收盤價垂直線',
              verticalArrow: '向下箭頭垂直線',              
            }
          },
          navigation: {
            popup: {
              addButton:"新增",
              editButton:"編輯",
              searchIndicators:"搜尋指標",
              fontSize:"字體大小",
              ranges:"區間",
              standardDeviation:"標準差",
              color:"顏色"

            }
          }           
      },
      credits: {
        enabled: true,
        href: 'https://future.ctinfo.com.tw/#/',
        text: '理財'
      },                   
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  //console.log('to',to,'from',from,'next',next);
  if (to.meta.requiresAuth) {
    if (localStorage.getItem("access_token") == "") {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!localStorage.getItem("isAuthenticated")) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
//<script src="https://code.highcharts.com/modules/annotations-advanced.js"></script>
