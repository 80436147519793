<template>
    <div class="balanceList">
        <div id="myvalue2" class="myvalue2">
            <!-- <div style="padding-left:10px; height: 20px;">
                <div class="myvaluename myid"><span id="myName">----</span> <span class="text-danger"
                        style="font-size:12px;font-weight:bold;">測試-<span></span></span></div>
            </div> -->
            <ul class="myvaluecashPop">
                <li>
                    <div class="myaaadlab" data-aa="a12">昨日權益數</div>
                    <div id="yCash" class="mycash " style="color: rgb(220, 53, 69);">0</div>
                </li>
                <li>
                    <div class="myaaadlab" data-aa="a13">今日損益</div>
                    <div id="tCash" class="mycash " style="color: rgb(204, 204, 204);">0</div>
                </li>
                <li>
                    <div class="myaaadlab" data-aa="a14">可用餘額</div>
                    <div id="aCash" class="mycash " style="color: rgb(220, 53, 69);">0</div>
                </li>
                <li>
                    <div class="myaaadlab" data-aa="a15">強平額度</div>
                    <div id="pCash" class="mycash" style="color: rgb(40, 167, 69);">0</div>
                </li>
                <li>
                    <div class="myaaadlab" data-aa="a16">留倉保證金</div>
                    <div id="eCash" class="mycash" style="color: rgb(204, 204, 204);">0</div>
                </li>
                <li>
                    <div class="myaaadlab" data-aa="a17">總權益數</div>
                    <div id="aCash0" class="mycash" style="color: rgb(220, 53, 69);">0</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    // import ModalWindow from "@vuesence/modal-window"
    import $ from 'jquery'
    import Order from './order.vue';
    import Stomp from 'stompjs'
    import Store from '../store/index.js'
    import { Window } from '@progress/kendo-vue-dialogs';
    import { Button } from '@progress/kendo-vue-buttons';
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

    import { Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect } from '@/store/RabbitMQ.js';
    import { Start_SignalR_Connect } from '@/store/SignalRHub.js'

    import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
    import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
    import axios from 'axios';

    // import Top from '@/components/Top.vue'
    // import Left from '@/components/Left.vue'
    // import Footer from '@/components/Footer.vue'
    // import Right from '@/components/Right.vue'
    /* eslint-disable no-debugger, no-console */
    export default {
        components: {

        },
        data: function () {
            return {
                userData: [],                // 用於存放使用者資料 | 20231030 | HENRY |
                nowMyWallet: "",             // 用於存放使用者總額度 | 20231030 | HENRY |
                nowMyLock: "",               // 用於存放使用者被鎖定額度 | 20231030 | HENRY |
            }
        },
        created() {

        },
        destroyed() {
            MQ_DisConnect();
        },
        mounted() {
            // this.getUserData();
            this.getMyFutureWalletAmount();
        },
        methods: {
            //|20231030|會員能查詢目前帳戶餘額|HENRY|
            //|20231116|加入非同步執行|PUDDING|
            getMyFutureWalletAmount() {
                axios.get(this.$httpPath + '/UserManager/myFutureWalletAmount')
                    .then((response) => {
                        if (response.status === 200) {
                            this.nowMyWallet = response.data;
                            const myCash = document.getElementById('aCash0');
                            if (myCash) {
                                myCash.textContent = this.nowMyWallet;
                            }
                            this.getFutureLockAmount();
                        } else {
                            console.error("getMyFutureWalletAmount(), Response not 200:", response.status);
                        }
                    }
                    )
            },
            //|20231030|會員能查詢目前帳戶鎖定餘額  |HENRY|
            //|20231108|目前帳戶鎖定餘額NaN問題修正 |HENRY|
            getFutureLockAmount() {
                axios.get(this.$httpPath + '/UserManager/myFutureLockAmount')
                    .then((response) => {
                        if (response.status === 200) {
                            this.nowMyLock = response.data;

                            // 確保從後端獲取的數據是有效的數字字符串
                            // 如果不是，設定一個預設值，比如 0
                            const lockAmount = parseInt(this.nowMyLock || '0', 10);
                            const totalAmount = parseInt(this.nowMyWallet || '0', 10);

                            // 檢查轉換後的數字是否為 NaN
                            if (isNaN(lockAmount) || isNaN(totalAmount)) {
                                console.error('Invalid number received from server', this.nowMyLock, this.nowMyWallet);
                                return; // 提前返回，不執行下面的代碼
                            }

                            // 計算 myQuota
                            const myQuota = totalAmount + lockAmount;

                            // 更新 UI
                            const myCashElement = document.getElementById('aCash');
                            if (myCashElement) {
                                myCashElement.textContent = myQuota.toString();
                            }
                        } else {
                            console.error("getFutureLockAmount(), Response not 200:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("getFutureLockAmount(), Request failed:", error);
                    });
            },
        },
    };
</script>
<style scoped>
    #myvalue2 {
        /* position: absolute;
        top: 0;
        left: 0; */
        margin-top: 30px;
        width: 100%;
    }

    .myvaluename {
        font-family: assistant-bold, sans-serif !important;
        font-weight: initial !important;
    }

    .myvaluecashPop {
        margin-top: 10px;
        height: 110px;
    }

    .myvaluecashPop li div.myaaadlab {
        font-size: 12px;
        vertical-align: top;
        float: left;
        width: 38%;
        padding-left: 10px;
        color: #828282;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .myvaluecashPop li div.mycash {
        font-size: 14px;
        float: left;
        text-align: right;
        vertical-align: top;
        font-family: assistant-semibold, sans-serif;
        font-weight: 500;
        width: 55%;
    }
</style>