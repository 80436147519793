var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"griddiv-all",style:({ height: _vm.dynamicHeight })},[_c('Grid',{ref:"grid_ALL",staticStyle:{"max-height":"100%","overflow-y":"auto"},attrs:{"id":"grid_ALL","data-items":_vm.items,"selected-field":_vm.selectedField,"columns":_vm.columns},on:{"rowclick":_vm.onRowClick},scopedSlots:_vm._u([{key:"myCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{style:(_vm.cellBackground(props.dataItem))},[_vm._v(" "+_vm._s(props.dataItem[props.field])+" "),(props.dataItem.uintVolatility < 0)?_c('span',[_c('span',{staticClass:"k-icon k-i-sort-desc-sm"})]):_c('span',[_c('span',{staticClass:"k-icon k-i-sort-asc-sm"})])])]}},{key:"myIntHighPrice",fn:function(ref){
var props = ref.props;
return [_c('td',{style:(_vm.myIntHighPriceBackground(props.dataItem))},[_vm._v(" "+_vm._s(props.dataItem[props.field])+" ")])]}},{key:"myIntLowPrice",fn:function(ref){
var props = ref.props;
return [_c('td',{style:(_vm.myIntLowPriceBackground(props.dataItem))},[_vm._v(" "+_vm._s(props.dataItem[props.field])+" ")])]}},{key:"pricechangeTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"text-center"},[(props.dataItem['nAmplitude'] < 0)?_c('div',[_c('font',{attrs:{"color":"##82FF82"}},[_vm._v(" "+_vm._s(props.dataItem['nBid'])+" ")])],1):_c('div',[_c('font',{attrs:{"color":"#E00000"}},[_vm._v(" "+_vm._s(props.dataItem['nBid'])+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }