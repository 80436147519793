<template>
  <div style="height: 100%;">
    <div class="root">
      <!-- <Dialog v-if="is_lost_connection" style="z-index:99;" :width="'375px'" :height="'auto'" :closeIcon="false">
        <div style="display:flex; justify-content:center; margin:10px 0px; padding:5px 10px;">
          <span style="font-size:30px;">
            連線異常
          </span>
        </div>
        網路連線不穩定，正在連線重新連線
        <DialogActionsBar>
          <div style="display:flex; justify-content:center; margin-bottom:0px">
            <Button :fill-mode="'link'" style="color:#007bff" :rounded="'medium'" v-text="'軟體使用條款'"></Button>
          </div>
        </DialogActionsBar>
      </Dialog> -->
      <b-sidebar id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow>
        <template #default="{ hide }">
          <div class="p-3">
            <h4 id="sidebar-no-header-title">Custom header sidebar</h4>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
              in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <nav class="mb-3">
              <b-nav vertical>
                <b-nav-item active @click="hide">Active</b-nav-item>
                <b-nav-item href="#link-1" @click="hide">Link</b-nav-item>
                <b-nav-item href="#link-2" @click="hide">Another Link</b-nav-item>
              </b-nav>
            </nav>
            <b-button variant="primary" block @click="hide">Close Sidebar</b-button>
          </div>
        </template>
      </b-sidebar>
      <Dialog v-if="login_visibleDialog" style="z-index:100;" :width="'375px'" :height="'auto'" :closeIcon="false"
        @close="Login_ToggleDialog">
        <div style="display:flex; justify-content:center; margin:10px 0px; padding:5px 10px;">
          <span style="font-size:30px;">
            創兆
          </span>
        </div>
        <div style="display:flex; justify-content:center;margin:10px 20px;">
          <Input style="font-size:16px; border-radius:4px; border:1px solid #ced4da;" :icon-name="'k-icon k-i-user'"
            :style="{ width: '100%', height:'33px'}" :placeholder="'帳號'" v-model="logindata.email" />
        </div>
        <div style="display:flex; justify-content:center; margin:10px 20px;">
          <Input style="font-size:16px; border-radius:4px; border:1px solid #ced4da;"
            v-bind:type="showPassword ? 'text' : 'password'" :style="{ width: '100%', height:'33px'}"
            :placeholder="'密碼'" v-model="logindata.password" />
        </div>
        <div style="display:flex; justify-content:center; margin:10px 20px;">
          <Button :fill-mode="'link'" @click="Login_Click"
            style="background-color:#007bff; color:#FFFF;height:48px; width:100%;" :rounded="'medium'"
            :disabled="isLoginButtonDisabled" v-text="'登入'"></Button>
        </div>
        <input type="checkbox" v-model="showPassword" style="font-size:20px;  margin-left:20px;"> 顯示密碼 <br>
        <div style="text-align: center;"> <!-- Container for the content -->
          <div v-if="loginStatus == 1" class="spinner-border text-secondary" role="status"
            style="display: block; margin: auto;">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-if="loginStatus == 2" id="success">
            登入成功，請稍候
          </div>
          <div v-if="loginStatus == 3" id="fail">
            帳號或密碼錯誤 {{countFailTime}} 次
          </div>
        </div>
        <div style="display:flex; justify-content:flex-end; margin:10px 20px;">
          <div
            style="display:flex; background-color:#9fcdff; align-items:center; justify-content:center; font-weight:bold; font-size:13px; border-radius:4px; color:black; border:1px solid #ced4da;"
            :style="{ width: '107px', height:'20px'}" @click="update_Click" v-text="'版本:T24APR24'" />
        </div>
        <DialogActionsBar>
          <div style="display:flex; justify-content:center; margin-bottom:0px">
            <Button :fill-mode="'link'" style="color:#007bff" :rounded="'medium'" v-text="'軟體使用條款'"></Button>
          </div>
        </DialogActionsBar>
      </Dialog>
      <b-navbar toggleable type="dark" variant="dark">
        <svg v-b-toggle.sidebar-no-header xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
          class="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
        </svg>
        <b-navbar-brand href="#"><span id="myName">----</span> <span class="text-danger"
            style="font-size:12px;font-weight:bold;"><span></span></span></b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse" @click="handleNavbarToggleClick">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
            <b-icon v-else icon="chevron-bar-down"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" v-model="isCollapseVisible" is-nav>
          <div id="dv-t" ref="dv-t">
            <balanceList></balanceList>
          </div>
        </b-collapse>
      </b-navbar>
      <div class="page_01" style="margin-top: 0px;">
        <!-- 
          +-----------------------------------------------+   ^
          |                                               |   |
          |                                               |   |
          |                 balanceList                   |   dv-t
          |                                               |   |
          |                                               |   |
          +-----------------------------------------------+   V
          +-----------------------------------------------+   ^
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                  mainTab                      |  dv-c
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          |                                               |   |
          +-----------------------------------------------+   V
          |                                               |   
          +-----------------------------------------------+   ^
          |                  footer                       |   |
          |                                               |  dv-f      
          +-----------------------------------------------+   V
      !-->
        <div class="Main_Page" id="Main_Page" ref="Main_Page">
          <!-- Top Section (dv-t) -->
          <!-- <div id="dv-t" ref="dv-t">
            <balanceList></balanceList>
          </div> -->


          <!-- Center Section (dv-c) -->
          <div id="dv-c" ref="dv_c">
            <b-tabs v-model="currentTab" ref="mainTabs">
              <b-tab active>
                <template #title>
                  <div :style="{ width: dynamicWidth, height: '30px', textAlign: 'center' }">
                    主頁
                  </div>
                </template>
                <mainTop id="dv-lt" class="dv-t" ref="leftLT" @productGridOnRowClick="handleProductGridOnRowClick">
                </mainTop>
              </b-tab>
              <b-tab @click="getTabSize()">
                <template #title>
                  <div :style="{ width: dynamicWidth, height: '30px', textAlign: 'center' }">
                    產品
                  </div>
                </template>
                <productInfo ref="productInfo" @resizeEvent="handleResizeEvent"></productInfo>
              </b-tab>
              <!-- Add additional tabs as needed -->
              <b-tab>
                <template #title>
                  <div :style="{ width: dynamicWidth, height: '30px', textAlign: 'center' }">
                    下單
                  </div>
                </template>
                <order ref="rightB"></order>
              </b-tab>
              <b-tab>
                <template #title>
                  <div :style="{ width: dynamicWidth, height: '30px', textAlign: 'center' }">
                    庫存
                  </div>
                </template>
                <mainDown id="dv-lta" class="dv-ta" ref="leftLD"></mainDown>
              </b-tab>
              <b-tab>
                <template #title>
                  <div :style="{ width: dynamicWidth, height: '30px', textAlign: 'center' }">
                    設定
                  </div>
                </template>
                <settingsPage></settingsPage>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- Footer Section (dv-f) -->
      <div id="dv-f" ref="dv-f">
        <!-- <div class="navigation-row">
          <div v-for="navOption in navigationOptions" :key="navOption" class="navigation-cell">
            {{ navOption }}
          </div>
        </div> -->
      </div>
      <bottombar style="z-index:2;"></bottombar>
    </div>
  </div>
</template>
<script scoped src="../assets/js/base64.js"></script>
<script scoped src="../assets/js/basic.js"></script>
<script scoped src="../assets/js/bootstrap.bundle.min.js"></script>
<script scoped src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script scoped src="../assets/js/color-picker.min.js"></script>
<script scoped src="../assets/js/gunzip.min.js"></script>
<script scoped src="../assets/js/jQuery.fastClick.js"></script>
<script scoped src="../assets/js/jquery.nicescroll.js"></script>
<script scoped src="../assets/js/jquery.translate.js"></script>
<script scoped src="../assets/js/jquery-3.0.0.min.js"></script>
<script scoped src="../assets/js/jsbn.js"></script>
<script scoped src="../assets/js/kChart.js"></script>
<script scoped src="../assets/js/kkk.js"></script>
<script scoped src="../assets/js/loadBar.js"></script>
<script scoped src="../assets/js/main.js"></script>
<script scoped src="../assets/js/modernizr-custom.js"></script>
<script scoped src="../assets/js/mTW.js"></script>
<script scoped src="../assets/js/prng4.js"></script>
<script scoped src="../assets/js/rng.js"></script>
<script scoped src="../assets/js/rsa.js"></script>
<script scoped src="../assets/js/splitter.js"></script>
<script scoped src="../assets/js/tinyscrollbar.js"></script>
<script scoped src="../assets/js/top.js"></script>
<script scoped src="../assets/js/tread.js"></script>
<script scoped src="../assets/js/value.js"></script>
<style scoped src="../assets/css/bootstrap.min.css"></style>
<style scoped src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style scoped src="../assets/css/color-picker.min.css"></style>
<style scoped src="../assets/css/style.css"></style>
<style scoped src="../assets/css/color-picker.min.css"></style>


<script>
  // import ModalWindow from "@vuesence/modal-window"
  import { Splitpanes, Pane } from '@/components/splitpanes/index';
  import menueBox from '@/components/menueBox';
  import mainTop from '@/components/mainTop';
  import mainDown from '@/components/mainDown';
  import mainBottom from '@/components/mainBottom';
  import mainRight from '@/components/mainRight';
  import k_menubox from '@/components/k_menubox';
  import k_newpricebox from '@/components/k_newpricebox';
  import bottombar from '@/components/bottombar';
  import KLeft from '@/components/KLeft';
  import HighChartPopupL from '@/components/HighChartPopupL';
  import HighChartPopupL_KLine from '@/components/HighChartPopupL_KLine';
  import order from '@/components/order';
  import realTimePrice from '@/components/realTimePrice';
  import balanceList from '@/components/balanceList';
  import settingsPage from '@/components/settingsPage';
  import productInfo from '@/components/productInfo';

  import $ from 'jquery'
  import Order from './order.vue';
  import Stomp from 'stompjs'
  import Store from '../store/index.js'
  import { Window } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';
  import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

  import { Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect } from '@/store/RabbitMQ.js';
  import { Start_SignalR_Connect } from '@/store/SignalRHub.js'

  import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
  import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
  import axios from 'axios';
  import router from '@/router'


  // import Top from '@/components/Top.vue'
  // import Left from '@/components/Left.vue'
  // import Footer from '@/components/Footer.vue'
  // import Right from '@/components/Right.vue'
  /* eslint-disable no-debugger, no-console */
  export default {
    components: {
      productInfo,
      settingsPage,
      balanceList,
      Dialog,
      DialogActionsBar,
      Input,
      Button,
      // ModalWindow,
      Splitpanes,
      Pane,
      menueBox,
      mainTop,
      mainDown,
      mainBottom,
      mainRight,
      k_menubox,
      k_newpricebox,
      bottombar,
      KLeft,
      order,
      realTimePrice
    },
    data: function () {
      return {
        dynamicWidth: '85px', // 初始值
        countFailTime: 0,
        isLoginButtonDisabled: true,
        loginStatus: 0,
        isCollapseVisible: true,
        currentTab: 0,
        topFunctions: [
          ["全部單", "未平倉單", "已平倉", "統計"],
          ["公告總覽", "報價明細", "會員資訊", "帳戶歷史"],
          ["儲值記錄", "修改密碼", "開盤風控設定", "規則說明"],
        ],
        centerFunctions: [
          ["產品一區", "產品二區", "產品三區"],
          ["產品四區", "產品五區", "產品六區"],
        ],
        navigationOptions: ["home", "A", "B", "C", "D"],
        showPassword: false,
        showModal: false,
        visible: false,
        login_visibleDialog: true,
        login_account: "",
        login_password: "",
        L_Pane_Size: 60,
        R_Pane_Size: 40,
        LT_Pane_Size: 40,
        LB_Pane_Size: 60,
        products: [],
        postit: 'coustom',
        // use for login Ricky
        logindata: {
          email: '',
          password: '',
          model: '',
        },
        encrypt_logindata: {
          email: '',
          password: '',
          model: '',
        },
        // use for login Ricky
        userData: [],                // 用於存放使用者資料 | 20231030 | HENRY |
        nowMyWallet: "",             // 用於存放使用者總額度 | 20231030 | HENRY |
        nowMyLock: "",               // 用於存放使用者被鎖定額度 | 20231030 | HENRY |
      }
    },
    created() {

    },
    destroyed() {
      window.removeEventListener("resize", this.windowResize);
      MQ_DisConnect();
    },
    computed: {
      is_lost_connection() {
        return Store.state.is_lost_connection;
      },
      signalr_connection_id() {
        return Store.state.client_hub_register_info.signalr_connection_id;
      },
    },
    mounted() {
      console.log("login")
      this.loginStatus = 1;
      axios.get(this.$httpPath + '/AgentManager/checkVip')
        .then((response) => {
          if (response.status === 200) {
            console.log('登入正確');
            Start_SignalR_Connect();
            //User_MQ_Initialization_Connect("TC00001", "Abc@1234");/*api需補充帳密回傳*/
            //User_MQ_Initialization_Connect(this.logindata.email, this.logindata.password);
            this.Login_ToggleDialog();
            this.loginStatus = 2;
          }
        }
        )
        .catch((error) => {
          this.loginStatus = 0;
          this.isLoginButtonDisabled = false;
        })
      //this.$refs.leftRT.reslzeRT(0, 255);
      this.getUserData();
      this.getMyFutureWalletAmount();
      // this.getFutureLockAmount(); // 放入 getMyFutureWalletAmount() 內 PUDDING
      this.getTabSize();
      this.$nextTick(() => {
        // 在下一次 DOM 更新周期之后执行代码
        this.getTabSize();
      });
    },
    watch: {
      currentTab() {
        // console.log(this.currentTab);
        this.getTabSize();
      },
      isCollapseVisible() {
        // console.log(this.isCollapseVisible);
        this.getTabSize();
      },
      signalr_connection_id() {
        this.registerSignalrConnId();
      },
    },
    methods: {
      resizeWidth(page_width) {
        let singleWidth = page_width / 5 - 5;
        this.setDynamicWidthByInt(singleWidth);
      },
      setDynamicWidthByInt(px) {
        this.dynamicWidth = px.toString() + 'px'
      },
      update_Click() {
        alert("update", navigator)
        console.log(navigator)
        console.log('serviceWorker' in navigator)
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistration().then(reg => {
            console.log("navigator.serviceWorker.getRegistration()")
            console.log("reg", reg)
            console.log("reg.waiting", reg.waiting)
            reg.update().then(function () {
              console.log("updated")
            });
          });
        }
      },
      registerSignalrConnId() {
        axios.get(this.$httpPath + '/UserManager/registerSignalrConnectionId?connId=' + this.signalr_connection_id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Response OK:", response.data);
            } else {
              console.error("Response ERR:", response.status);
            }
          })
          .catch((error) => {
            console.error("Request failed:", error);
          });
      },
      handleProductGridOnRowClick() {
        this.currentTab = 1; // 设置当前 Tab 为第二个标签页
      },
      handleNavbarToggleClick() {
        // console.log('Navbar toggle button clicked!', this.isCollapseVisible);
      },
      //|20231228|子物件的tab按下去後reseize|RICKY|
      handleResizeEvent() {
        // console.log("handleResizeEvent");
        this.getTabSize();
      },
      //|20231030|取得會員基本資料|HENRY|
      async getUserData() {
        axios.get(this.$httpPath + '/UserManager/myAccountInfo')
          .then((response) => {
            if (response.status === 200) {
              this.userData = response.data;
              const userName = document.getElementById('myName');
              if (userName) {
                userName.textContent = this.userData.userName;
              }
            } else {
              console.error("getUserData(), Response not 200:", response.status);
            }
          }
          )
      },
      getTabSize() {
        const productTab = this.$refs.dv_c;
        // console.log(productTab);
        if (productTab) {
          const dvCSize = productTab.getBoundingClientRect();
          // console.log('dv-c size:', dvCSize);
          // console.log(this.isCollapseVisible)
          if (this.isCollapseVisible) {
            this.resizeWidth(dvCSize.width);
            this.$refs.leftLD.resize(dvCSize.width, dvCSize.height);
            this.$refs.leftLT.resize(dvCSize.width, dvCSize.height);
            this.$refs.productInfo.resizeWidth(dvCSize.width);
            this.$refs.productInfo.$refs.leftLK_KL.resizeLK(dvCSize.width, dvCSize.height - 220);
            this.$refs.productInfo.$refs.leftLK.resizeLK(dvCSize.width, dvCSize.height - 220);
            this.$refs.leftLD.resizeGrid(dvCSize.width, dvCSize.height - 225);
            this.$refs.leftLT.$refs.productAllGrid.resizeGrid(dvCSize.width, dvCSize.height - 175);
            this.$refs.leftLT.$refs.productTWEMERGINGGrid.resizeGrid(dvCSize.width, dvCSize.height - 175);
            this.$refs.leftLT.$refs.productTAIFEXGrid.resizeGrid(dvCSize.width, dvCSize.height - 175);
            this.$refs.leftLT.$refs.productTWSEGrid.resizeGrid(dvCSize.width, dvCSize.height - 175);
          }
          else {
            this.$refs.productInfo.$refs.leftLK_KL.resizeLK(dvCSize.width, dvCSize.height - 90);
            this.$refs.productInfo.$refs.leftLK.resizeLK(dvCSize.width, dvCSize.height - 90);
            this.$refs.leftLD.resizeGrid(dvCSize.width, dvCSize.height - 85);
            this.$refs.leftLT.$refs.productAllGrid.resizeGrid(dvCSize.width, dvCSize.height - 35);
            this.$refs.leftLT.$refs.productTWEMERGINGGrid.resizeGrid(dvCSize.width, dvCSize.height - 35);
            this.$refs.leftLT.$refs.productTAIFEXGrid.resizeGrid(dvCSize.width, dvCSize.height - 35);
            this.$refs.leftLT.$refs.productTWSEGrid.resizeGrid(dvCSize.width, dvCSize.height - 35);
          }
        }
      },
      async Login_Click() {
        this.loginStatus = 1;
        this.isLoginButtonDisabled = true;
        this.encrypt_logindata.email = Encrypt(this.logindata.email)
        this.encrypt_logindata.password = Encrypt(this.logindata.password)
        this.encrypt_logindata.model = Encrypt(this.logindata.model)
        //this.$store.dispatch('logIn', this.encrypt_logindata);
        var isLogin = await Store.dispatch('logIn', this.encrypt_logindata);
        console.log("isLogin", isLogin);
        if (isLogin) {
          this.loginStatus = 2;
          location.reload();
        }
        else {
          this.loginStatus = 3;
          this.countFailTime++;
          if (this.countFailTime < 3) {
            setTimeout(() => {
              this.isLoginButtonDisabled = false;
            }, 1500);
          }
          else {
            this.countFailTime = "3--LOCK"
          }
        }
      },
      Login_ToggleDialog() {
        /*login_account  帳號 */
        /*login_password 密碼*/

        this.login_visibleDialog = false;
        console.log(this.login_visibleDialog)
      },
      Change_showModal() {
        console.log(1)
        this.showModal = !this.showModal;
      },
      getProductsResponse(ProductsResponse) {
        console.log("getProductsResponse給order");
        console.log(typeof (ProductsResponse));
        this.products = ProductsResponse;
      },

      //|20231030|取得會員基本資料|HENRY|
      async getUserData() {
        axios.get(this.$httpPath + '/UserManager/myAccountInfo')
          .then((response) => {
            if (response.status === 200) {
              this.userData = response.data;
              const userName = document.getElementById('myName');
              if (userName) {
                userName.textContent = this.userData.userName;
              }
            } else {
              console.error("getUserData(), Response not 200:", response.status);
            }
          }
          )
      },

      //|20231030|會員能查詢目前帳戶餘額|HENRY|
      //|20231116|加入非同步執行|PUDDING|
      getMyFutureWalletAmount() {
        axios.get(this.$httpPath + '/UserManager/myFutureWalletAmount')
          .then((response) => {
            if (response.status === 200) {
              this.nowMyWallet = response.data;
              const myCash = document.getElementById('aCash');
              if (myCash) {
                myCash.textContent = this.nowMyWallet;
              }
              this.getFutureLockAmount();
            } else {
              console.error("getMyFutureWalletAmount(), Response not 200:", response.status);
            }
          }
          )
      },

      //|20231030|會員能查詢目前帳戶鎖定餘額  |HENRY|
      //|20231108|目前帳戶鎖定餘額NaN問題修正 |HENRY|
      getFutureLockAmount() {
        axios.get(this.$httpPath + '/UserManager/myFutureLockAmount')
          .then((response) => {
            if (response.status === 200) {
              this.nowMyLock = response.data;

              // 確保從後端獲取的數據是有效的數字字符串
              // 如果不是，設定一個預設值，比如 0
              const lockAmount = parseFloat(this.nowMyLock || '0', 10);
              const totalAmount = parseFloat(this.nowMyWallet || '0', 10);

              // 檢查轉換後的數字是否為 NaN
              if (isNaN(lockAmount) || isNaN(totalAmount)) {
                console.error('Invalid number received from server', this.nowMyLock, this.nowMyWallet);
                return; // 提前返回，不執行下面的代碼
              }

              // 計算 myQuota
              const myQuota = totalAmount + lockAmount;

              // 更新 UI
              const myCashLock = document.getElementById('eCash');
              const myCashElement = document.getElementById('aCash0');
              if (myCashElement) {
                myCashElement.textContent = myQuota.toString();
              }
              if (myCashLock) {
                myCashLock.textContent = lockAmount.toString();
              }
            } else {
              console.error("getFutureLockAmount(), Response not 200:", response.status);
            }
          })
          .catch((error) => {
            console.error("getFutureLockAmount(), Request failed:", error);
          });
      },
    },
  };
</script>
<style scoped>
  .modal-mask {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  .modal-container {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
  }

  .modal-body {
    cursor: auto;
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
  }

  .dv-lbl {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  .dv-lbr {
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  body {
    color: #ccc !important;
  }

  .default-theme.splitpanes .splitpanes .splitpanes__splitter {
    z-index: 0 !important;
  }

  .splitpanes__pane {
    box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }

  .splitpanes__pane>span {
    font-family: Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 5em;
    opacity: 0.7;
  }

  .vue-modal-resizer {
    display: block;
    overflow: hidden;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: transparent;
    cursor: se-resize;
  }

  .vue-modal-resizer::after {
    display: block;
    position: absolute;
    content: '';
    background: transparent;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid #ddd;
    border-left: 10px solid transparent;
  }

  .vue-modal-resizer.clicked::after {
    border-bottom: 10px solid #369be9;
  }

  .v--modal-block-scroll {
    overflow: hidden;
    width: 100vw;
  }

  .v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 1;
  }

  .v--modal-overlay.scrollable {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .v--modal-overlay .v--modal-background-click {
    width: 100%;
    min-height: 100%;
    height: auto;
  }

  .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  .v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 2px;
  }

  .v--modal {
    background-color: white;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    padding: 0;
  }

  .v--modal.v--modal-fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
  }

  .v--modal-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  .overlay-fade-enter-active,
  .overlay-fade-leave-active {
    transition: all 0.2s;
  }

  .overlay-fade-enter,
  .overlay-fade-leave-active {
    opacity: 0;
  }

  .nice-modal-fade-enter-active,
  .nice-modal-fade-leave-active {
    transition: all 0.4s;
  }

  .nice-modal-fade-enter,
  .nice-modal-fade-leave-active {
    opacity: 0;
    transform: translateY(-20px);
  }

  .vue-dialog div {
    box-sizing: border-box;
  }

  .vue-dialog .dialog-flex {
    width: 100%;
    height: 100%;
  }

  .vue-dialog .dialog-content {
    flex: 1 0 auto;
    width: 100%;
    padding: 15px;
    font-size: 14px;
  }

  .vue-dialog .dialog-c-title {
    font-weight: 600;
    padding-bottom: 15px;
  }

  .vue-dialog .vue-dialog-buttons {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    border-top: 1px solid #eee;
  }

  .vue-dialog .vue-dialog-buttons-none {
    width: 100%;
    padding-bottom: 15px;
  }

  .vue-dialog-button {
    font-size: 12px !important;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 40px;
    height: 40px;
    color: inherit;
    font: inherit;
    outline: none;
  }

  .vue-dialog-button:hover {
    background: rgba(0, 0, 0, 0.01);
  }

  .vue-dialog-button:active {
    background: rgba(0, 0, 0, 0.025);
  }

  .vue-dialog-button:not(:first-of-type) {
    border-left: 1px solid #eee;
  }

  /* main page  */
  .page_01 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .root {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
  }

  .Main_Page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex: 1;
    /* 新增這一行 */
  }

  .b-tabs {
    flex-grow: 1;
    height: 100%;
  }

  .tabs {
    flex-grow: 1;
    height: 100%;
  }

  .tab-content {
    flex: 1;
    height: 100%;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: #343a40;
    color: #fff;
    z-index: 2;
  }

  .function-row {
    display: flex;
  }

  .function-cell,
  .navigation-cell {
    flex: 1;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }

  .navigation-row {
    display: flex;
    margin-bottom: 5px;
  }

  .navigation-cell {
    flex: 1;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }

  #dv-t {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 120px;
  }

  #dv-c {
    margin-top: 0px;
    flex-grow: 1;
    display: flex;
    height: 100%;
  }

  .dv-ta {
    overflow: scroll;
  }

  /* main page  */
</style>