import Stomp from 'stompjs'
import axios from 'axios';
import store from './index.js';//store import //coconut補充
import Vue from 'vue';
import Vuex from 'vuex';
import * as signalR from '@aspnet/signalr'

var connection = new signalR.HubConnectionBuilder()
    // .withUrl("https://localhost:44344/Client_Hub")
    .withUrl("https://m.future.ctinfo.com.tw/back/Client_Hub")
    .configureLogging(signalR.LogLevel.None)
    .build();
var connection_line = new signalR.HubConnectionBuilder()
    // .withUrl("https://localhost:44344/LineHub")
    .withUrl("https://m.future.ctinfo.com.tw/back/LineHub")
    .configureLogging(signalR.LogLevel.None)
    .build();


export function Start_SignalR_Connect() {
    console.log("開始訂閱")
    console.log(Vue.prototype.$httpPath_signalR)

    function startConnection(e = 999) {
        // Attempt to start the connection
        if (e == 999) {
            startClientConnect();
            startLineConnect();
        }
        else if (e == 1) {
            startClientConnect();
        }
        else if (e == 2) {
            startLineConnect();
        }
    }

    function startClientConnect() {
        connection.start()
            .then(() => {
                console.log("connection Connected!");
                initializeClientSignalrEvent();
            })
            .catch(err => {
                console.error("SignalR Connection Error: ", err);
                // If an error occurred, wait 5 seconds and try again
                setTimeout(() => startConnection(1), 5000);
            });
    }

    function startLineConnect() {
        connection_line.start()
            .then(() => {
                console.log("connection_line Connected!");
                initializeLineSignalrEvent();
            })
            .catch(err => {
                console.error("SignalR Connection Error: ", err);
                // If an error occurred, wait 5 seconds and try again
                setTimeout(() => startConnection(2), 5000);
            });
    }

    function initializeClientSignalrEvent() {
        connection.invoke("User_Login", "T605")
            .catch(function (err) {
                return console.error(err.toString());
            });
        connection.on("my_signalr_connection_id", (product) => {
            console.log("my_signalr_connection_id", product);
            store.state.client_hub_register_info.signalr_connection_id = product
        });
        connection.on("UpdateByApi", (product) => {
            console.log("UpdateByApi", product);
            store.state.UpdateByApi = product
        });
    }
    function initializeLineSignalrEvent(){
        connection_line.on("RealTime_Client", (product) => {
            let objJson = JSON.parse(product);
            store.dispatch("Filter_Real_Time_Product", objJson);
            store.state.isConnect = 0;
            objJson = null;
        });
        connection_line.on("TickOnly", (product) => {
            if (product.length > 0) {
                if (product[0].productCode == store.state.nowSelectProduct.productCode) {
                    store.state.historyTick = product;
                }
            }
        });
        connection_line.on("Tick_Client", (product) => {
            let objJson = JSON.parse(product);
            store.state.allTick = objJson;
            objJson = null;
        });
        connection.on("kLine_Client", (product) => {
            if (product.length > 0 && product[0].productCode == store.state.nowSelectProduct.productCode) {
                store.state.allKLineMin = product;
            }
        });
        connection_line.on("TimeLineOnly", (product) => {
            if (product.length > 0) {
                const item = product.find(item => item.productCode == store.state.nowSelectProduct.productCode);
                // console.log(store.state.nowSelectProduct.productCode)
                store.state.TimeLineOnly = item;
            }
        });
        connection_line.on("KLineMinOnly", (product) => {
            // console.log("KLineMinOnly", product)
            if (product.length > 0) {
                const item = product.find(item => item.productCode == store.state.nowSelectProduct.productCode);
                store.state.kLineMinOnly = item;
                // console.log("KLineMinOnly", item)
            }
        });
        connection_line.on("KLineFiveMinOnly", (product) => {
            // console.log(product);
            if (product.length > 0 && product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineFiveMinOnly = product;
            }
        });
        connection_line.on("KLineFifteenMinOnly", (product) => {
            // console.log("KLineFifteenMinOnly : " + product);
            if (product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineFifteenMinOnly = product;
            }
        });
        connection_line.on("KLineThirtyMinOnly", (product) => {
            // console.log("KLineThirtyMinOnly : " + product);
            if (product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineThirtyMinOnly = product;
            }
        });
        connection_line.on("KLineOneDayOnly", (product) => {
            // console.log("KLineOneDayOnly : " + product);
            if (product != [] && product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineOneDayOnly = product;
            }
        });
        connection_line.on("KLineOneWeekOnly", (product) => {
            // console.log("KLineOneWeekOnly : " + product);
            if (product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineOneWeekOnly = product;
            }
        });
        connection_line.on("KLineOneMonthOnly", (product) => {
            // console.log("KLineOneMonthOnly : " + product);
            if (product.length > 0 && product.productCode == store.state.nowSelectProduct.productCode) {
                store.state.kLineOneMonthOnly = product;
            }
        });
        connection_line.on("BestFiveTick_Client", (product) => {
            let objJson = JSON.parse(product);
            if (objJson.productCode == store.state.nowSelectProduct.productCode) {
                store.state.allBestFiveTick = objJson;
            }
            objJson = null;
        });
        connection_line.on("connectSuccess", (product) => {
            store.state.isConnect = 0;
        });
    }

    // Connection closed event
    connection.onclose(() => {
        console.log("connection Connection lost, trying to reconnect...");
        // Attempt to reconnect with a delay
        store.state.is_lost_connection = true;
        connection.stop().then(function () {
            console.log('Connection stopped, reconnecting...');
            setTimeout(() => startConnection(1), 1000);
        }).catch(function (error) {
            console.error('Error stopping connection for reconnect:', error);
        });
    });
    // Connection closed event
    connection_line.onclose(() => {
        console.log("connection_line Connection lost, trying to reconnect...");
        // Attempt to reconnect with a delay
        store.state.is_lost_connection = true;
        connection_line.stop().then(function () {
            console.log('connection_line Connection stopped, reconnecting...');
            setTimeout(() => startConnection(2), 1000);
        }).catch(function (error) {
            console.error('Error stopping connection for reconnect:', error);
        });
    });

    // Start the connection for the first time
    startConnection();
};

export function Subscribe(topic) {
    console.log(topic);
    connection.on(topic, (message) => {
        console.log(topic)
        console.log(message)
        store.dispatch(topic + "DataActions", message);
    });
}
export function unSubscribe(topic) {
    connection.on(topic, (message) => {
        console.log(message)
    });
}
