<template>
    <div class="productInfo" ref="productInfo">
        <b-tabs v-model="currentTab" ref="mainTabs" style="background-color:#181818;font-size:0.9rem">
            <b-tab active>
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }">
                        分時
                    </div>
                </template>
                <HighChartPopupL ref="leftLK"></HighChartPopupL>
            </b-tab>
            <b-tab>
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }" ref="productInfoTab_VL">
                        價量
                    </div>
                </template>
                <realTimePrice ref="leftRT"></realTimePrice>
            </b-tab>
            <!-- Add additional tabs as needed -->
            <b-tab>
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }" ref="productInfoTab_VL">
                        Ｋ線
                    </div>
                </template>
                <HighChartPopupL_KLine ref="leftLK_KL"></HighChartPopupL_KLine>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    // import ModalWindow from "@vuesence/modal-window"
    import $ from 'jquery'
    import Order from './order.vue';
    import Stomp from 'stompjs'
    import Store from '../store/index.js'
    import { Window } from '@progress/kendo-vue-dialogs';
    import { Button } from '@progress/kendo-vue-buttons';
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

    import { Monitorin_MQ_Initialization_Connect, User_MQ_Initialization_Connect, Monitorin_MQ_Subscribe, MQ_DisConnect } from '@/store/RabbitMQ.js';
    import { Start_SignalR_Connect } from '@/store/SignalRHub.js'

    import { Checkbox, Input, RadioGroup, TextArea } from '@progress/kendo-vue-inputs';
    import { Encrypt, Decrypt } from '@/utils/aes.js'//Ricky: AES
    import axios from 'axios';

    import HighChartPopupL from '@/components/HighChartPopupL';
    import HighChartPopupL_KLine from '@/components/HighChartPopupL_KLine';
    import realTimePrice from '@/components/realTimePrice';

    export default {
        components: {
            HighChartPopupL,
            HighChartPopupL_KLine,
            realTimePrice,
        },
        data: function () {
            return {
                currentTab: 0,
                dynamicWidth: '85px' // 初始值
            }
        },
        watch: {
            currentTab() {
                // console.log(this.currentTab);
                this.DoResize();
            }
        },
        created() {

        },
        destroyed() {

        },
        mounted() {

        },
        methods: {
            setDynamicWidthByInt(px){
                this.dynamicWidth = px.toString() + 'px'
            },
            DoResize() {
                console.log("DoResize",this.currentTab);
                this.$emit('resizeEvent');
            },
            resizeWidth(page_width){
                let singleWidth = page_width / 3 - 10;
                this.setDynamicWidthByInt(singleWidth);
            },
        },
    };
</script>
<style scoped>

</style>