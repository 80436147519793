<template>
    <div>
        <div id="containerKL_Kline"></div>
    </div>
</template>
<script src="https://code.highcharts.com/stock/highstock.js"></script>
<script src="https://code.highcharts.com/stock/modules/data.js"></script>

<script src="https://code.highcharts.com/stock/modules/drag-panes.js"></script>

<script src="https://code.highcharts.com/stock/indicators/indicators.js"></script>
<script src="https://code.highcharts.com/stock/indicators/bollinger-bands.js"></script>
<script src="https://code.highcharts.com/stock/indicators/ema.js"></script>



<script src="../assets/highcharts/code/es-modules/extensions/fullscreen.js"></script>
<script src="https://code.highcharts.com/stock/modules/price-indicator.js"></script>
<script src="https://code.highcharts.com/stock/modules/stock-tools.js"></script>
<script>

    import Vue from "vue";
    import Stomp from 'stompjs'
    import $ from 'jquery'
    import axios from "axios";
    import Highcharts from 'highcharts';
    import VueToast from 'vue-toast-notification';
    import Store from '../store/index.js'

    Vue.use(VueToast);
    export default {
        name: "HighChartPopupL_KLine",
        data: function () {
            return {
                //webSocketConnect
                // client:Stomp.client(Vue.prototype.$httpPath_ws),
                // nowConnectionWs:null,
                //webSocketConnect
                kLineFifteenMinData:[],
                kLineThirtyMinData:[],
                kLineOneDayData:[],
                kLineOneWeekData:[],
                kLineOneMonthData:[],
                allKLineMinStoreBuffer: [],
                selectedField: 'selected',
                updatedData: [],
                editID: null,
                selectedID: null,
                group: [{ field: 'UnitsInStock' }],
                expandedItems: [],
                chartL: null,
                gridData: [],
                kLine_status: 0, // 0 api推送歷史資料 1 正在接收即時資料 2 鎖定初始化圖表用
                lastkLine:'',
                lastTimeStamp:0,
                kLineType:'One',
                startMorningSlot:new Date().setHours(8, 39, 0, 0),
                endMorningSlot: new Date().setHours(8, 44, 59, 999),
                fakeInitData: [
                    {
                        "productCode": "init",
                        "firstIntDeal": "0",
                        "lastIntDeal": "0",
                        "maxIntDeal": "0",
                        "minIntDeal": "0",
                        "timestamp": 1707161100000
                    }
                ],
                ChartOptions:
                {
                    chart:
                    {
                        backgroundColor: '#000000',
                        reflow: true,
                        // events: {
                        //     load: function () {
                        //         addPopupEvents(this);
                        //     }
                        // },
                    },
                    plotOptions:
                    {
                        candlestick: {
                            tooltip: {
                                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                                    '開盤: {point.open}<br/>' +
                                    '最高: {point.high}<br/>' +
                                    '最低: {point.low}<br/>' +
                                    '收盤: {point.close}<br/>'
                            },
                            upLineColor: 'red',
                            upColor: 'red',
                            LineColor: 'green',
                            color: 'green',
                            border: 'green',
                        },
                        line: {
                            color: 'yellow',
                        }
                    },
                    xAxis:
                    {
                        gridLineWidth: 1,
                        gridLineColor: '#501111',
                    },
                    yAxis:
                        [{
                            labels: {
                                align: 'left'
                            },
                            height: '100%',
                            resize: {
                                enabled: true
                            },
                            gridLineWidth: 1,
                            gridLineColor: '#501111',
                        },
                        {
                            labels: {
                                align: 'left'
                            },
                            top: '80%',
                            height: '20%',
                            offset: 10
                        }],
                    scrollbar:
                    {
                        enabled: true
                    },
                    navigation: {
                        // Informs Stock Tools where to look for HTML elements for adding
                        // technical indicators, annotations etc.
                        bindingsClassName: 'tools-container',
                        annotationsOptions: {
                            shapeOptions: {
                                fill: "#ffff00",
                                stroke: "#ffff00",
                                strokeWidth: 1
                            }
                        },
                        bindings:
                        {
                            customZoom:
                            {
                                className: 'highcharts-custom-zoom',
                                init: function (e) {
                                    vm.$parent.$parent.$parent.$parent.$parent.$parent.$parent.maxSize();
                                }
                            }
                        },
                    },
                    navigationBindings:
                    {
                        events:
                        {
                            selectButton: function (event) {
                                var newClassName = event.button.className + ' highcharts-active',
                                    topButton = event.button.parentNode.parentNode;

                                if (topButton.classList.contains('right')) {
                                    newClassName += ' right';
                                }

                                // If this is a button with sub buttons,
                                // change main icon to the current one:
                                if (!topButton.classList.contains('highcharts-menu-wrapper')) {
                                    topButton.className = newClassName;
                                }

                                // Store info about active button:
                                vm.chart.activeButton = event.button;
                            },
                            deselectButton: function (event) {
                                event.button.parentNode.parentNode.classList.remove('highcharts-active');

                                // Remove info about active button:
                                this.chart.activeButton = null;
                            },
                            showPopup: function (event) {

                                if (!this.indicatorsPopupContainer) {
                                    this.indicatorsPopupContainer = document
                                        .getElementsByClassName('highcharts-popup-indicators')[0];
                                }

                                if (!this.annotationsPopupContainer) {
                                    this.annotationsPopupContainer = document
                                        .getElementsByClassName('highcharts-popup-annotations')[0];
                                }

                                if (event.formType === 'indicators') {
                                    this.indicatorsPopupContainer.style.display = 'block';
                                } else if (event.formType === 'annotation-toolbar') {
                                    // If user is still adding an annotation, don't show popup:
                                    if (!this.chart.activeButton) {
                                        this.chart.currentAnnotation = event.annotation;
                                        this.annotationsPopupContainer.style.display = 'block';
                                    }
                                }

                            },
                            closePopup: function () {
                                this.indicatorsPopupContainer.style.display = 'none';
                                this.annotationsPopupContainer.style.display = 'none';
                            }
                        }
                    },
                    tooltip:
                    {
                        shape: 'square',
                        headerShape: 'callout',
                        borderWidth: 0,
                        shadow: false,
                        positioner: function (width, height, point) {
                            var chart = this.chart,
                                position;

                            if (point.isHeader) {
                                position = {
                                    x: Math.max(
                                        // Left side limit
                                        chart.plotLeft,
                                        Math.min(
                                            point.plotX + chart.plotLeft - width / 2,
                                            // Right side limit
                                            chart.chartWidth - width - chart.marginRight
                                        )
                                    ),
                                    y: point.plotY
                                };
                            } else {
                                position = {
                                    x: point.series.chart.plotLeft,
                                    y: point.series.yAxis.top - chart.plotTop
                                };
                            }

                            return position;
                        }
                    },
                    series:
                        [{
                            type: 'candlestick',
                            id: '',
                            name: '',
                            data: []
                        }, {
                            type: 'column',
                            id: '',
                            name: '',
                            data: [],
                            yAxis: 1
                        }],
                    responsive:
                    {
                        rules:
                            [{
                                condition: {
                                },
                                chartOptions: {
                                    rangeSelector: {
                                        inputEnabled: false
                                    }
                                }
                            }]
                    },
                    rangeSelector: {
                        buttons: [{
                            type: 'Day',
                            count: 1,
                            text: '日',
                            events: {
                                click: () => {
                                    this.changeKLineData('OneDay');
                                    this.getKLineOneDayData();
                                }
                            }
                        }, {
                            type: 'Week',
                            count: 1,
                            text: '週',
                            events: {
                                click: () => {
                                    this.changeKLineData('OneWeek');
                                    this.getKLineOneWeekData();
                                }
                            }
                        }, {
                            type: 'Month',
                            count: 1,
                            text: '月',
                            events: {
                                click: () => {
                                    this.changeKLineData('OneMonth');
                                    this.getKLineOneMonthData();
                                }
                            }
                        }, {
                            type: 'Min',
                            count: 1,
                            text: '1分',
                            events: {
                                click: () => {
                                    this.changeKLineData('One');
                                    this.getKLineMinData();
                                }
                            }
                        }, {
                            type: 'Min',
                            count: 5,
                            text: '5分',
                            events: {
                                click: () => {
                                    this.changeKLineData('Five');
                                    this.getKLineFiveMinData();
                                }
                            }
                        }, {
                            type: 'Min',
                            count: 15,
                            text: '15分',
                            events: {
                                click: () => {
                                    this.changeKLineData('Fifteen');
                                    this.getKLineFifteenMinData();
                                }
                            }
                        }, {
                            type: 'Min',
                            count: 30,
                            text: '30分',
                            events: {
                                click: () => {
                                    this.changeKLineData('Thirty');
                                    this.getKLineThirtyMinData();
                                }
                            }
                        }],
                        selected: 1,
                        inputEnabled: false
                    },
                    stockTools:
                    {
                        gui:
                        {
                            buttons: ['indicators',
                                //'simpleShapes',
                                'lines',
                                'crookedLines',
                                //'measure',
                                'advanced',
                                //'toggleAnnotations',
                                //'verticalLabels',
                                'flags',
                                'zoomChange',
                                'fullScreen',
                                'typeChange',
                                'currentPriceIndicator',
                                //'customZoom' Tony : customize gui button
                            ],
                            definitions:
                            {
                                // Tony : customize gui button
                                // customZoom: 
                                // {
                                //     className: 'highcharts-custom-zoom',
                                // },
                            }
                        },
                    },
                    lang: {
                        navigation:
                        {
                            popup: {
                                stroke: "線顏色",
                                strokeWidth: "線寬"
                            }
                        },
                    },
                },
            }
        },
        created() {
            //this.refreshAll();            
        },
        components: {
        },
        computed: {
            nowSelectProduct() {
                return Store.state.nowSelectProduct;
            },
            kLineMinOnly() {
                return Store.state.kLineMinOnly;
            },
            kLineMinHistory() {
                return Store.state.kLineMinHistory;
            },
            kLineFiveMinOnly() {
                return Store.state.kLineFiveMinOnly;
            },
            kLineFiveMinHistory() {
                return Store.state.kLineFiveMinHistory;
            },
            kLineFifteenMinOnly() {
                return Store.state.kLineFifteenMinOnly;
            },
            kLineThirtyMinOnly() {
                return Store.state.kLineThirtyMinOnly;
            },
            kLineOneDayOnly() {
                return Store.state.kLineOneDayOnly;
            },
            kLineOneWeekOnly() {
                return Store.state.kLineOneWeekOnly;
            },
            kLineOneMonthOnly() {
                return Store.state.kLineOneMonthOnly;
            },
        },
        watch: {
            nowSelectProduct() {
                // console.log("nowSelectProduct__________")
                this.ChartOptions.series[0].data = [];
                this.kLine_status = 0;
                this.kLineType = 'One';
                axios.get(this.$httpPath + '/Line/getKLineMinHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("Response OK:", response.data);
                            this.kLine_Data_Push(response.data);
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
                console.log("nowSelectProduct__________", this.kLine_status)
            },
            kLineOneDayOnly() {
                let vm = this;
                const now = new Date();

                if (vm.kLine_status == 1 && vm.kLineType == 'OneDay') {
                    if (vm.lastTimeStamp != vm.kLineOneDayOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineOneDayOnly);
                    }
                    vm.lastTimeStamp = vm.kLineOneDayOnly.timestamp;
                }

                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineOneWeekOnly() {
                let vm = this;
                const now = new Date();
                
                if (vm.kLine_status == 1 && vm.kLineType == 'OneWeek') {
                    if (vm.lastTimeStamp != vm.kLineOneWeekOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineOneWeekOnly);
                    }
                    vm.lastTimeStamp = vm.kLineOneWeekOnly.timestamp;
                }

                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineOneMonthOnly() {
                let vm = this;
                const now = new Date();
                
                if (vm.kLine_status == 1 && vm.kLineType == 'OneMonth') {
                    if (vm.lastTimeStamp != vm.kLineOneMonthOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineOneMonthOnly);
                    }
                    vm.lastTimeStamp = vm.kLineOneMonthOnly.timestamp;
                }

                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineMinOnly() {
                let vm = this;
                const now = new Date();

                if (vm.kLine_status == 1 && vm.kLineType == 'One') {
                    // console.log("watch kLineMinOnly")
                    if(!this.isSameMinute(vm.lastTimeStamp, vm.kLineMinOnly.timestamp)){
                        vm.kLine_Data_Add(vm.kLineMinOnly);
                        // console.log("watch kLineMinOnly: add", vm.kLineMinOnly)
                    }
                    vm.lastTimeStamp = vm.kLineMinOnly.timestamp;
                }

                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineFiveMinOnly() {
                let vm = this;
                const now = new Date();

                if (vm.kLine_status == 1 && vm.kLineType == 'Five') {
                    if (vm.lastTimeStamp != vm.kLineFiveMinOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineFiveMinOnly);
                    }
                    vm.lastTimeStamp = vm.kLineFiveMinOnly.timestamp;
                }
                
                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineFifteenMinOnly() {
                let vm = this;
                const now = new Date();

                if (vm.kLine_status == 1 && vm.kLineType == 'Fifteen') {
                    if (vm.lastTimeStamp != vm.kLineFifteenMinOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineFifteenMinOnly);
                    }
                    vm.lastTimeStamp = vm.kLineFifteenMinOnly.timestamp;
                }
                
                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            kLineThirtyMinOnly() {
                let vm = this;
                const now = new Date();

                if (vm.kLine_status == 1 && vm.kLineType == 'Thirty') {
                    if (vm.lastTimeStamp != vm.kLineThirtyMinOnly.timestamp) {
                        vm.kLine_Data_Add(vm.kLineThirtyMinOnly);
                    }
                    vm.lastTimeStamp = vm.kLineThirtyMinOnly.timestamp;
                }
                
                //清除當天K線有問題要再處理
                if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
                    vm.ChartOptions.series[0].data = [];
                    vm.ChartOptions.series[1].data = [];
                }
            },
            // kLineMinHistory() {

            //     let vm = this;
            //     const now = new Date();

            //     if (vm.kLine_status == 0 && vm.kLineType == 'One') {
            //         vm.kLine_Data_Push(vm.kLineMinHistory);
            //         vm.kLine_status++;
            //     }


            //     if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
            //         vm.ChartOptions.series[0].data = [];
            //         vm.ChartOptions.series[1].data = [];
            //     }

            // },
            // kLineFiveMinHistory() {
            //     let vm = this;
            //     const now = new Date();

            //     if (vm.kLine_status == 0 && vm.kLineType == 'Five') {
            //         vm.kLine_Data_Push(vm.kLineFiveMinHistory);
            //         vm.kLine_status++;
            //     }


            //     if (now >= vm.startMorningSlot && now <= vm.endMorningSlot) {
            //         vm.ChartOptions.series[0].data = [];
            //         vm.ChartOptions.series[1].data = [];
            //     }
            // },
        },
        methods: {
            InDevelopment() {
                alert("功能開發中！")
            },
            isSameMinute(timestamp1, timestamp2) {
                // 将时间戳转换为分钟，并取整数部分
                const minute1 = Math.floor(timestamp1 / 60000);
                const minute2 = Math.floor(timestamp2 / 60000);
                // 比较转换后的分钟数是否相等
                return minute1 === minute2;
            },
            // 用於切換產品初始化K線圖表
            changeKLineData(kLineType) {
                console.log("changeKLineData:",kLineType);
                let vm = this;
                vm.kLineType = kLineType;
                vm.kLine_status = 2;
                vm.lastkLine = '';
                vm.lastTimeStamp = 0;
                vm.ChartOptions.series[0].data = [];
                vm.ChartOptions.series[1].data = [];
                vm.kLine_status = 0;
            },
            // 用於取得日K線資料
            getKLineOneDayData() {
                let vm = this;
                
                axios.get(this.$httpPath + '/Line/getOneDayKLineHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLine_Data_Push(response.data);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得周K線資料
            getKLineOneWeekData() {
                let vm = this;

                axios.get(this.$httpPath + '/Line/getOneWeekKLineHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLine_Data_Push(response.data);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得月K線資料
            getKLineOneMonthData() {
                let vm = this;

                axios.get(this.$httpPath + '/Line/getOneMonthKLineHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLine_Data_Push(response.data);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得分鐘K線資料
            getKLineMinData() {
                let vm = this;

                axios.get(this.$httpPath + '/Line/getKLineMinHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLine_Data_Push(response.data);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得5分鐘K線資料
            getKLineFiveMinData() {
                let vm = this;

                axios.get(this.$httpPath + '/Line/getKLineFiveMinHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLine_Data_Push(response.data);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得15分鐘K線資料
            getKLineFifteenMinData() {
                let vm = this;
                axios.get(this.$httpPath + '/Line/getFifteenMinKLineHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLineFifteenMinData = response.data;
                            vm.kLine_Data_Push(vm.kLineFifteenMinData);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            // 用於取得30分鐘K線資料
            getKLineThirtyMinData() {
                let vm = this;
                axios.get(this.$httpPath + '/Line/getThirtyMinKLineHistory?productCode=' + Store.state.nowSelectProduct.productCode)
                    .then((response) => {
                        if (response.status === 200) {
                            vm.kLineThirtyMinData = response.data;
                            vm.kLine_Data_Push(vm.kLineThirtyMinData);
                            // vm.kLine_status++;
                        } else {
                            console.error("Response ERR:", response.status);
                        }
                    })
                    .catch((error) => {
                        console.error("Request failed:", error);
                    });
            },
            //用於推送歷史資料
            kLine_Data_Push(frame) {
                let vm = this;
                let ohlc = [];
                let volume = [];

                var pushLastTime = '';
                if (vm.kLine_status == 0) {
                    for (let i = 0; i < frame.length; i++) {
                        if(pushLastTime != frame[i]['timestamp'] || pushLastTime == ''){
                            ohlc.push([
                                parseFloat(frame[i]['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                                parseFloat(frame[i]['firstIntDeal']), // open -> 開盤價
                                parseFloat(frame[i]['maxIntDeal']), // high -> 最高價
                                parseFloat(frame[i]['minIntDeal']), // low -> 最低價
                                parseFloat(frame[i]['lastIntDeal'])  // close -> 關盤價
                            ]);
                            volume.push([
                                parseFloat(frame[i]['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                                parseFloat(frame[i]['dwVol']) // the volume -> 賣出單位
                            ]);
                            pushLastTime = frame[i]['timestamp'];
                        }
                    }
                    vm.ChartOptions.series[0].data = ohlc;
                    vm.ChartOptions.series[0].id = Store.state.nowSelectProduct.productCode;
                    vm.ChartOptions.series[0].name = Store.state.nowSelectProduct.productName + '價格';
                    vm.ChartOptions.series[1].data = volume;
                    vm.ChartOptions.series[1].id = Store.state.nowSelectProduct.productCode;
                    vm.ChartOptions.series[1].name = Store.state.nowSelectProduct.productName + '單量';

                    vm.getCandleStickData();
                    console.log('Data Push');
                    vm.kLine_status = 1;
                }
                ohlc = null;
                volume = null;

            },
            //用於推送及時資料
            kLine_Data_Add(frame) {
                console.log("kLine_Data_Add: ", frame);
                let vm = this;
                let ohlc = [];
                let volume = [];

                const date = new Date(vm.lastkLine['timestamp']);
                const year = date.getFullYear();
                const month = (date.getMonth()).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                var timestamp = `${year}-${month}-${day} ${hours}:${minutes}`;
                const framedate = new Date(frame['timestamp']);
                const frameyear = framedate.getFullYear();
                const framemonth = (framedate.getMonth()).toString().padStart(2, '0');
                const frameday = framedate.getDate().toString().padStart(2, '0');
                const framehours = framedate.getHours().toString().padStart(2, '0');
                const frameminutes = framedate.getMinutes().toString().padStart(2, '0');
                var frametimestamp = `${frameyear}-${framemonth}-${frameday} ${framehours}:${frameminutes}`;
                if (timestamp != frametimestamp && vm.lastkLine != '' && (vm.kLineType != 'Fifteen' && vm.kLineType != 'Thirty' && vm.kLineType != 'OneDay' && vm.kLineType != 'OneWeek' && vm.kLineType != 'OneMonth')) {
                    console.log("add");
                    ohlc.push([
                    parseFloat(frame['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                        parseFloat(frame['firstIntDeal']), // open -> 開盤價
                        parseFloat(frame['maxIntDeal']), // high -> 最高價
                        parseFloat(frame['minIntDeal']), // low -> 最低價
                        parseFloat(frame['lastIntDeal'])  // close -> 關盤價
                    ]);

                    volume.push([
                        parseFloat(frame['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                        parseFloat(frame['dwVol']) // the volume -> 賣出單位
                    ]);

                    const candleSeries = vm.chartL.series.find(series => series.type === 'candlestick');
                    candleSeries.addPoint(ohlc[0]);
                    const columnSeries = vm.chartL.series.find(series => series.type === 'column');
                    columnSeries.addPoint(volume[0]);
                }
                else if (timestamp == frametimestamp && vm.lastkLine != '' && (vm.kLineType == 'Fifteen' && vm.kLineType == 'Thirty' && vm.kLineType == 'OneDay' && vm.kLineType == 'OneWeek' && vm.kLineType == 'OneMonth')) {
                    ohlc.push([
                        parseFloat(vm.lastkLine['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                        parseFloat(frame['firstIntDeal']), // open -> 開盤價
                        parseFloat(frame['maxIntDeal']), // high -> 最高價
                        parseFloat(frame['minIntDeal']), // low -> 最低價
                        parseFloat(frame['lastIntDeal'])  // close -> 關盤價
                    ]);

                    volume.push([
                        parseFloat(vm.lastkLine['timestamp']), // the date -> 以毫秒為單位 起始日期:1970-01-01 00:00:00
                        parseFloat(frame['dwVol']) // the volume -> 賣出單位
                    ]);

                    const candleSeries = vm.chartL.series.find(series => series.type === 'candlestick');
                    candleSeries.addPoint(ohlc[0]);
                    const columnSeries = vm.chartL.series.find(series => series.type === 'column');
                    columnSeries.addPoint(volume[0]);
                }
                vm.lastkLine = frame;
                ohlc = null;
                volume = null;
            },
            resizeLK(width, height) {
                let vm = this;
                document.getElementById('containerKL_Kline').style.width = width + 'px';
                document.getElementById('containerKL_Kline').style.height = height - 40 + 'px';
                if (vm.chartL != null)
                    vm.chartL.reflow();
            },
            maxSize() {
                this.chartL.$parent.$parent.$parent.$parent.$parent.$parent.$parent.maxSize();
            },
            getCandleStickData() {
                // console.log("getCandleStickData()getCandleStickData()");
                let vm = this;
                vm.chartL = Highcharts.stockChart('containerKL_Kline', vm.ChartOptions);
                let height = document.body.clientHeight * 4 / 6 - 125;
                let width = document.body.clientWidth * 0.8 - 15;
                // vm.resizeLK(width, height);
                height = null;
                width = null;
                //以下模擬資料更新的樣子
                // localStorage.setItem("is_test", 0);
                
                // setInterval(() => {
                //     if (localStorage.getItem("is_test") == 1) {
                //         // console.log("測試模式ＯＮ")
                //         // console.log("模擬新的tick進來")
                //         const series_candlestick = vm.chartL.series[0];
                //         const data_candlestick = series_candlestick.options.data;
                //         const lastPoint_candlestick = data_candlestick[data_candlestick.length - 1];
                //         const newPoint_candlestick = lastPoint_candlestick[4] += 10; // RICKY:k線圖會動的地方只有收盤價，所以模擬只改這邊
                //         series_candlestick.options.data[data_candlestick.length - 1][4] = newPoint_candlestick; // RICKY:k線圖會動的地方只有收盤價，所以模擬只改這邊
                //         series_candlestick.setData(data_candlestick);
                //         const series_column = vm.chartL.series[1];
                //         const data_column = series_column.options.data;
                //         const lastPoint_column = data_column[data_column.length - 1];
                //         const newPoint_column = lastPoint_column[1] += 10; // RICKY:交易量會變動的只有這邊
                //         series_column.options.data[data_column.length - 1][1] = newPoint_column; // RICKY:交易量會變動的只有這邊
                //         series_column.setData(data_column);
                //     }
                // }, 1000);
            },
        },
        mounted() {
            axios.get(this.$httpPath + '/AgentManager/checkVip')
                .then((response) => {
                    if (response.status === 200) {
                        console.log('登入確認');
                        this.getKLineMinData();
                    }
                })
        },
    };
    function addPopupEvents(chart) {
    }
</script>
<style>
    .highcharts-background,
    .highcharts-root,
    .highcharts-container {
        max-height: 100%;
        max-width: 100%;
        padding-left: 10px;
    }

    .highcharts-menu-wrapper {
        height: 100 !important;
        z-index: 0 !important;
    }

    .highcharts-scrollbar-arrow {
        width: 25px;
        height: 14px !important;
    }

    .highcharts-scrollbar-thumb {
        height: 14px !important;
        display: block;
    }

    .highcharts-scrollbar {
        height: 14px !important;
        display: block;
    }

    .highcharts-background .highcharts-root .highcharts-container {
        max-height: 100%;
        max-width: 100%;
    }

    /* GENERAL */
    .container-fluid {
        height: 100% !important;
        width: 100% !important;
        overflow: auto;
    }

    .highcharts-draw-mode {
        cursor: crosshair;
    }

    .highcharts-background,
    .highcharts-root,
    .highcharts-container {
        max-height: 100%;
        max-width: 100%;
    }

    .highcharts-stocktools-wrapper,
    .highcharts-bindings-wrapper,
    .highcharts-bindings-container {
        height: 0px !important;
    }


    /* GUI */

    .highcharts-stocktools-wrapper {
        display: block;
    }

    .highcharts-stocktools-toolbar {
        margin: 0 0 0 10px;
        padding: 0;
        width: calc(100% - 63px);
    }

    .highcharts-stocktools-toolbar li {
        background-color: #f7f7f7;
        background-repeat: no-repeat;
        cursor: pointer;
        float: left;
        height: 40px;
        list-style: none;
        margin-right: 2px;
        margin-bottom: 3px;
        padding: 0;
        position: relative;
        width: auto;
    }

    .highcharts-stocktools-toolbar li ul {
        display: none;
        left: 0;
        padding-left: 0;
        position: absolute;
        z-index: 125;
    }

    .highcharts-stocktools-toolbar li ul li {
        margin-bottom: 0;
        width: 160px;
    }

    .highcharts-stocktools-toolbar li:hover {
        background-color: #e6ebf5;
    }

    .highcharts-stocktools-toolbar li:hover ul {
        display: block;
    }

    .highcharts-stocktools-toolbar li>span.highcharts-menu-item-btn {
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: block;
        float: left;
        height: 100%;
        width: 25px;
    }

    .highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
        color: #666;
        line-height: 40px;
        font-size: 0.876em;
        padding: 0 10px 0 5px;
    }

    .highcharts-indicators>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/indicators.svg");
    }

    .highcharts-label-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/label.svg");
    }

    .highcharts-circle-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/circle.svg");
    }

    .highcharts-rectangle-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/rectangle.svg");
    }

    .highcharts-ellipse-annotation>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/ellipse.svg");
    }

    .highcharts-segment>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/segment.svg");
    }

    .highcharts-arrow-segment>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-segment.svg");
    }

    .highcharts-ray>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/ray.svg");
    }

    .highcharts-arrow-ray>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-ray.svg");
    }

    .highcharts-infinity-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/line.svg");
    }

    .highcharts-arrow-infinity-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/arrow-line.svg");
    }

    .highcharts-horizontal-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/horizontal-line.svg");
    }

    .highcharts-vertical-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-line.svg");
    }

    .highcharts-elliott3>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-3.svg");
    }

    .highcharts-elliott5>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/elliott-5.svg");
    }

    .highcharts-crooked3>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-3.svg");
    }

    .highcharts-crooked5>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/crooked-5.svg");
    }

    .highcharts-measure-xy>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-xy.svg");
    }

    .highcharts-measure-x>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-x.svg");
    }

    .highcharts-measure-y>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/measure-y.svg");
    }

    .highcharts-fibonacci>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fibonacci.svg");
    }

    .highcharts-pitchfork>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/pitchfork.svg");
    }

    .highcharts-parallel-channel>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/parallel-channel.svg");
    }

    .highcharts-toggle-annotations>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/annotations-visible.svg");
    }

    .highcharts-vertical-counter>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-counter.svg");
    }

    .highcharts-vertical-label>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-label.svg");
    }

    .highcharts-vertical-arrow>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-arrow.svg");
    }

    .highcharts-vertical-double-arrow>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/vertical-double-arrow.svg");
    }

    .highcharts-flag-circlepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-elipse.svg");
    }

    .highcharts-flag-diamondpin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-diamond.svg");
    }

    .highcharts-flag-squarepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-trapeze.svg");
    }

    .highcharts-flag-simplepin>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/flag-basic.svg");
    }

    .highcharts-zoom-xy>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-xy.svg");
    }

    .highcharts-custom-zoom {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
    }

    .highcharts-zoom-x>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-x.svg");
    }

    .highcharts-zoom-y>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/zoom-y.svg");
    }

    .highcharts-full-screen>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/fullscreen.svg");
    }

    .highcharts-series-type-ohlc>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-ohlc.svg");
    }

    .highcharts-series-type-line>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-line.svg");
    }

    .highcharts-series-type-candlestick>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/series-candlestick.svg");
    }

    .highcharts-current-price-indicator>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/current-price-show.svg");
    }

    .highcharts-save-chart>.highcharts-menu-item-btn {
        background-image: url("https://code.highcharts.com/gfx/stock-icons/save-chart.svg");
    }

    li.highcharts-active {
        background-color: #e6ebf5;
    }

    /* Popup */


    /* Responsive design */

    @media screen and (max-width: 1024px) {
        .highcharts-stocktools-toolbar li>.highcharts-menu-item-title {
            display: none;
        }

        .highcharts-stocktools-toolbar li ul li {
            width: auto;
        }
    }

    @import '../assets/highcharts/code/css/stocktools/gui.css';
    @import '../assets/highcharts/code/css/annotations/popup.css';
</style>