<template>
    <div>
        <b-tabs id="dv_b" class="dv-b" style="background-color:#181818;font-size:0.9rem">
            <b-tab @click="fetchOrders()">
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }">
                        全部單
                    </div>
                </template>
                <div ref="griddiv" :style="{ height: dynamicHeight }">
                    <button v-on:click="fetchOrders">更新資料</button>
                    <Grid ref="grid_all_order" id="grid_all_order" :data-items="this.orders" :columns="columns"
                        style="max-height: 100%; overflow-y: auto;">
                    </Grid>
                </div>
            </b-tab>
            <b-tab @click="fetchOrders()">
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }">
                        未平倉單
                    </div>
                </template>
                <div ref="griddiv" :style="{ height: dynamicHeight }">
                    <button v-on:click="fetchOrders">更新資料</button>
                    <Grid ref="grid_deal_order" id="grid_deal_order" :data-items="this.inTransactions"
                        :columns="columns2" style="max-height: 100%; overflow-y: auto;">
                        <template v-slot:myTemplate="{ props }">
                            <custom :data-item="props.dataItem" @closeDeal="showCloseDealPopup"
                                @changeDeal="showChangeDealPopup" />
                        </template>
                    </Grid>
                </div>
                <!-- Pudding 平倉限價或市價確認 -->
                <b-modal v-model="isCloseDealPopupVisible" hide-footer hide-header-close no-close-on-esc
                    no-close-on-backdrop>
                    <template #modal-title>
                        平倉選單
                    </template>
                    <form @submit.prevent="closeDeal" class="form-container">
                        <div class="form-check fleft">
                            <input v-model="closeDealMarketPrice" type="checkbox" class="form-check-input"
                                id="closeDeal_nowPrice">
                            <label class="form-check-label blackWord" for="closeDeal_nowPrice" data-aa="a58">市價</label>
                        </div>
                        <div class="form-group">
                            <label class="limit-price-label">限價：</label>
                            <span v-if="closeDealMarketPrice">
                                <input v-model.number="closeDealLimitPrice" class="form-control limit-price-input"
                                    type="number" min="0" id="closeDealLimitPrice" disabled>
                            </span>
                            <span v-else>
                                <input v-model.number="closeDealLimitPrice" class="form-control limit-price-input"
                                    type="number" min="0" id="closeDealLimitPrice">
                            </span>
                        </div>
                        <div class="button-container">
                            <KButton type="submit" :theme-color="'primary'" class="custom-button">平倉</KButton>
                            <KButton type="button" :theme-color="'primary'" class="custom-button"
                                @click="cancelCloseDeal">關閉</KButton>
                        </div>
                    </form>
                </b-modal>
                <!-- Pudding 平倉限價或市價確認 -->
                <!-- Ricky 換倉限價或市價確認 -->
                <b-modal v-model="isChangeDealPopupVisible" hide-footer hide-header-close no-close-on-esc
                    no-close-on-backdrop>
                    <template #modal-title>
                        換倉選單 ＝＝＝＝測試中＝＝＝＝
                    </template>
                    <form @submit.prevent="closeDeal" class="form-container">
                        <div class="form-group">
                            <label class="limit-price-label">商品：</label>
                            <span>
                                <input v-model="changeDealInfo.sealProductName" class="form-control limit-price-input"
                                    type="text" id="changeDealInfo.sealProductName" disabled>
                            </span>
                        </div>
                        <div class="form-group">
                            <label class="limit-price-label">賣出：</label>
                            <span>
                                <input v-model="changeDealInfo.sealProductNo" class="form-control limit-price-input"
                                    type="text" id="changeDealInfo.sealProductNo" disabled>
                            </span>
                        </div>
                        <div class="form-group">
                            <label class="limit-price-label">買進：{{changeDealInfo.buyProductGuid}}</label>
                            <span>
                                <select v-model="changeDealInfo.buyProductGuid" id="same_group_product_List_select">
                                    <optgroup label="請選擇">
                                        <Option v-for="(item,index) in same_group_product_List" :value="item.guid"
                                            :key="index">{{ item.lStockNo }}
                                        </Option>
                                    </optgroup>
                                </select>
                            </span>
                        </div>
                        <div class="form-check fleft">
                            <input v-model="switchDealMarketPrice" type="checkbox" class="form-check-input"
                                id="closeDeal_nowPrice">
                            <label class="form-check-label blackWord" for="closeDeal_nowPrice" data-aa="a58">市價</label>
                        </div>
                        <div class="form-group">
                            <label class="limit-price-label">限價：</label>
                            <span v-if="switchDealMarketPrice">
                                <input v-model.number="switchDealLimitPrice" class="form-control limit-price-input"
                                    type="number" min="0" step="0.01" id="closeDealLimitPrice" disabled>
                            </span>
                            <span v-else>
                                <input v-model.number="switchDealLimitPrice" class="form-control limit-price-input"
                                    type="number" min="0" step="0.01" id="closeDealLimitPrice">
                            </span>
                        </div>
                        <div class="button-container">
                            <KButton type="button" :theme-color="'primary'" class="custom-button" @click="switchDeal">換倉
                            </KButton>
                            <KButton type="button" :theme-color="'primary'" class="custom-button"
                                @click="cancelSwitchDeal">關閉</KButton>
                        </div>
                    </form>
                </b-modal>
                <!-- Ricky 換倉限價或市價確認 -->
            </b-tab>
            <b-tab @click="fetchOrders()">
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }">
                        已平倉
                    </div>
                </template>
                <div ref="griddiv" :style="{ height: dynamicHeight }">
                    <button v-on:click="fetchOrders">更新資料</button>
                    <Grid ref="grid_close_order" id="grid_close_order" :data-items="this.positionClosed"
                        :columns="columns" style="max-height: 1000px; overflow-y: auto;">
                    </Grid>
                </div>
            </b-tab>
            <b-tab @click="fetchOrders()">
                <template #title>
                    <div :style="{ width: dynamicWidth, height: '25px', textAlign: 'center' }">
                        統計
                    </div>
                </template>
                <div ref="griddiv" :style="{ height: dynamicHeight }">
                    <Grid ref="grid_statistics" id="grid_statistics" :data-items="this.profits" :columns="columns3"
                        style="max-height: 400px; overflow-y: auto;">
                    </Grid>
                </div>
                <!-- <div style="color:white">統計</div> -->
            </b-tab>
        </b-tabs>
    </div>
</template>
<script src="../assets/js/base64.js"></script>
<script src="../assets/js/basic.js"></script>
<script src="../assets/js/bootstrap.bundle.min.js"></script>
<script src="../assets/js/bootstrap-datetimepicker.min.js"></script>
<script src="../assets/js/color-picker.min.js"></script>
<script src="../assets/js/gunzip.min.js"></script>
<script src="../assets/js/jQuery.fastClick.js"></script>
<script src="../assets/js/jquery.nicescroll.js"></script>
<script src="../assets/js/jquery.translate.js"></script>
<script src="../assets/js/jquery-3.0.0.min.js"></script>
<script src="../assets/js/jsbn.js"></script>
<script src="../assets/js/kChart.js"></script>
<script src="../assets/js/kkk.js"></script>
<script src="../assets/js/loadBar.js"></script>
<script src="../assets/js/main.js"></script>
<script src="../assets/js/modernizr-custom.js"></script>
<script src="../assets/js/mTW.js"></script>
<script src="../assets/js/prng4.js"></script>
<script src="../assets/js/rng.js"></script>
<script src="../assets/js/rsa.js"></script>
<script src="../assets/js/splitter.js"></script>
<script src="../assets/js/tinyscrollbar.js"></script>
<script src="../assets/js/top.js"></script>
<script src="../assets/js/tread.js"></script>
<script src="../assets/js/value.js"></script>
<style src="../assets/css/bootstrap.min.css"></style>
<style src="../assets/css/bootstrap-datetimepicker.min.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<style src="../assets/css/style.css"></style>
<style src="../assets/css/color-picker.min.css"></style>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    // import VueToast from 'vue-toast-notification';
    import { Encrypt, Decrypt } from '../utils/aes.js';
    import closeDealBtn from './CommandCell/closeDealBtn.vue';
    import Store from '../store/index.js'
    import { Grid } from '@progress/kendo-vue-grid';
    import { Button as KButton } from "@progress/kendo-vue-buttons";
    // import '@progress/kendo-theme-default/dist/all.css';
    // Vue.use(VueToast);

    export default {
        name: 'mainDown',
        data: function () {
            return {
                dynamicWidth: '185px', // 初始值
                dynamicHeight: '350px',
                columns: [
                    { field: 'userName', title: '用戶名稱', editable: false, locked: true, width: '90px' },
                    { field: 'number', title: '訂單編號', editable: false, width: '150px' },// HENRY-UPDATE | 20231220 | 改不顯示訂單編號
                    { field: 'productName', title: '產品名稱', editable: false, width: '100px' },
                    { field: 'longOrShort', title: '買賣方向', editable: false, width: '100px' },
                    { field: 'price', title: '價格', editable: false, width: '100px' },
                    { field: 'amount', title: '數量', editable: false, width: '70px' },
                    { field: 'dealPrice', title: '成交價格', editable: false, width: '100px' },
                    { field: 'orderTime', title: '下單時間', editable: false, width: '100px' },
                    { field: 'dealTime', title: '成交時間', editable: false, width: '100px' },
                    { field: 'closePrice', title: '平倉價格', editable: false, width: '100px' },
                    { field: 'margin', title: '目前保證金', editable: false, width: '100px' },
                    { field: 'equity', title: '目前權益數', editable: false, width: '100px' },
                    { field: 'stopLoss', title: '止損', editable: false, width: '70px' },
                    { field: 'takeProfit', title: '獲利點', editable: false, width: '100px' },
                    { field: 'dealStatus', title: '交易狀態', editable: false, width: '100px' },
                    { field: 'endDate', title: '到期日', editable: false, width: '120px' }
                ],
                columns2: [
                    { cell: 'myTemplate', width: '120px' },
                    { field: 'userName', title: '用戶名稱', editable: false, locked: true, width: '90px' },
                    { field: 'number', title: '訂單編號', editable: false, width: '150px' },// HENRY-UPDATE | 20231220 | 改不顯示訂單編號
                    { field: 'productName', title: '產品名稱', editable: false, width: '100px' },
                    { field: 'longOrShort', title: '買賣方向', editable: false, width: '100px' },
                    { field: 'price', title: '價格', editable: false, width: '100px' },
                    { field: 'amount', title: '數量', editable: false, width: '70px' },
                    { field: 'dealPrice', title: '成交價格', editable: false, width: '100px' },
                    { field: 'orderTime', title: '下單時間', editable: false, width: '100px' },
                    { field: 'dealTime', title: '成交時間', editable: false, width: '100px' },
                    { field: 'closePrice', title: '平倉價格', editable: false, width: '100px' },
                    { field: 'margin', title: '目前保證金', editable: false, width: '100px' },
                    { field: 'equity', title: '目前權益數', editable: false, width: '100px' },
                    { field: 'stopLoss', title: '止損', editable: false, width: '100px' },
                    { field: 'takeProfit', title: '獲利點', editable: false, width: '70px' },
                    { field: 'dealStatus', title: '交易狀態', editable: false, width: '100px' },
                    { field: 'endDate', title: '到期日', editable: false, width: '120px' }
                ],
                columns3: [
                    { field: 'productName', title: '商品', editable: false, locked: true, width: '120px' },
                    { field: 'buyNumber', title: '未平多', editable: false, width: '100px' },
                    { field: 'sellNumber', title: '未平空', editable: false, width: '100px' },
                    { field: 'closeDealNumber', title: '已平倉', editable: false, width: '100px' },
                    { field: 'totalNumber', title: '總口數', editable: false, width: '100px' },
                    { field: 'commission', title: '手續費', editable: false, width: '100px' },
                    { field: 'totalProfit', title: '已平損益', editable: false, width: '100px' }
                ],
                orders: [],             // 用於存放使用者所下的單   |20231030|HENRY|
                inTransactions: [],     // 用於存放交易中的單       |20231030|HENRY|
                positionClosed: [],     // 用於存放已平倉的單       |20231030|HENRY|
                isCloseDealPopupVisible: false, // 平倉視窗的顯示狀態-->Pudding
                isChangeDealPopupVisible: false, // 換倉視窗       |20240408|HENRY|
                closeDealLimitPrice: 0, // 限價平倉數值-->Pudding
                switchDealLimitPrice: 0, // 限價換倉數值-->Pudding
                closeDealorderGuid: '', // 需平倉單Guid-->Pudding
                changeDealInfo: {       // 需換倉單Guid            |20240408|HENRY|
                    orderGuid: '',
                    sealProductGuid: '',
                    buyProductGuid: '',
                    sealWeek: '',
                    buyWeek: '',
                    sealProductName: '',
                    buyProuductName: '',
                    sealProductNo: '',
                    buyProuductNo: '',
                },
                closeDealMarketPrice: true, // 是否市價平倉-->Pudding
                switchDealMarketPrice: true, // 是否市價換倉-->Pudding
                profits: [],             // 使用者個商品損益-->Pudding
                same_group_product_List: [],
                switchMix: {
                    orderGuid: '', // 這邊放產品代號，由後端帶入guid
                    switchProductGuid: '', // 換倉產品代號，由後端帶入guid
                    switchType: '', // 換倉方式 0市價 1限價
                    switchPrice: '', // 換倉差價，如果是市價，就是市價的價格，但不表示成交金額，需要看後台搓合的結果
                    switchTime: '', // 換倉時間，前端的時間給後端段，如果差太多就不給換倉 UTC時間
                }
            }
        },
        components: {
            KButton,
            'custom': closeDealBtn,
            Grid,
            //test
        },
        computed: {
            UpdateByApi() {
                return Store.state.UpdateByApi;
            },
        },
        watch: {
            UpdateByApi() {
                // console.log(this.UpdateByApi);
                if (this.UpdateByApi == "/Trade/myDeal/100/1") {
                    this.fetchOrders();
                }
            },
        },
        mounted() {
            this.fetchOrders();
            this.fetchProfits();
        },
        methods: {
            resize(page_width, page_height) {
                let singleWidth = page_width / 4 - 5;
                this.setDynamicWidthByInt(singleWidth);
                this.setDynamicHeightByInt(page_height - 25);
            },
            setDynamicHeightByInt(px) {
                this.dynamicHeight = px.toString() + 'px';
            },
            setDynamicWidthByInt(px) {
                this.dynamicWidth = px.toString() + 'px'
            },
            //|20231216|讓tab大小相同|RICKY|
            renderTabTitle(tabName) {
                const productTab = this.$refs.dv_b;
                var singleWidth = 85;
                if (productTab) {
                    const dvCSize = productTab.getBoundingClientRect();
                    singleWidth = dvCSize.width / 4 - 5;
                }
                return (
                    <div style={{ width: `${singleWidth}px`, height: '90%', textAlign: 'center' }}>
                        {tabName}
                    </div>
                );
            },
            //|20231216|根據使用者調整的寬度調整ＧＲＩＤ的大小|RICKY|
            resizeGrid(width, height) {
                let vm = this;
                document.getElementById('grid_all_order').style.width = width - 10 + 'px';
                document.getElementById('grid_all_order').style.height = height - 80 + 'px';
                document.getElementById('grid_deal_order').style.width = width - 10 + 'px';
                document.getElementById('grid_deal_order').style.height = height - 80 + 'px';
                document.getElementById('grid_close_order').style.width = width - 10 + 'px';
                document.getElementById('grid_close_order').style.height = height - 80 + 'px';
                document.getElementById('grid_statistics').style.width = width - 10 + 'px';
                document.getElementById('grid_statistics').style.height = height - 55 + 'px';
            },
            //|20231027|取得自己的下單資料|HENRY|
            async fetchOrders() {
                try {
                    const response = await axios.get(this.$httpPath + '/Trade/myDeal/100/1');

                    if (response.status === 200) {
                        this.orders = response.data.data;
                        // HENRY-UPDATE | 20231220 | 不要讓訂單編號的字元卡掉, 會影響交易/平倉執行
                        // this.orders.forEach(order => {
                        //     if (order.number && typeof order.number === 'string') {
                        //         // 使用 slice 方法保留最後五個字元
                        //         order.number = order.number.slice(-5);
                        //     }
                        // });
                        // console.log(response.data.data);

                        // 格式化 orderTime 使其只顯示日期
                        this.orders.forEach(order => {
                            if (order.orderTime && typeof order.orderTime === 'string') {
                                order.orderTime = order.orderTime.split('T')[0];
                                order.dealTime = order.orderTime.split('T')[0];
                            }
                            if (order.price == -999) {
                                order.price = "市價";
                            }
                        });

                        //|20231030|取得自己交易中的單|HENRY|
                        // 篩選出 dealStatus 為 "交易中" 的項目
                        this.inTransactions = this.orders.filter(order => order.dealStatus === "交易中");

                        //|20231030|取得自己已平倉的單|HENRY|
                        // 篩選出 dealStatus 為 "已平倉" 的項目
                        this.positionClosed = this.orders.filter(order => order.dealStatus === "已平倉");
                    } else {
                        console.error("fetchOrders(), Response not 200:", response.status);
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            },
            //|20231113|取得自己的損益資料|Pudding|
            async fetchProfits() {
                try {
                    const response = await axios.get(this.$httpPath + '/Trade/getMyHistoryDealCount?pageSize=100&currentPage=1');

                    if (response.status === 200) {
                        this.profits = response.data.data;

                    } else {
                        console.error("fetchProfits(), Response not 200:", response.status);
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            },
            resizeLT: function (width, height) {
                this.$refs.griddiv.style.height = height - 100 + "px";
            },
            // Pudding Make 平倉用按鈕(已開放限價平倉)
            closeDeal() {
                try {
                    if (this.closeDealMarketPrice) {
                        this.closeDealLimitPrice = 0;
                    }
                    axios.post(this.$httpPath + '/Trade/closeDeal?orderGuid=' + this.closeDealorderGuid + '&closePrice=' + this.closeDealLimitPrice)
                        .then((response) => {
                            if (response.status == 200) {
                                this.fetchOrders();
                                alert("平倉申請送出");
                            }
                            else {
                                this.fetchOrders();
                                console.log(); ("平倉申請失敗");
                            }
                        })
                        .catch((error) => {
                            console.log(); ("平倉申請失敗");
                        }).finally(() => {
                            this.closeDealorderGuid = '';
                            this.isCloseDealPopupVisible = false;
                            this.fetchOrders();
                        });
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            },
            // Pudding Make 點擊未平倉單Grid平倉按鈕-->顯示視窗
            showCloseDealPopup(dataItem) {
                this.isCloseDealPopupVisible = true;
                // console.log(dataItem.number);
                this.closeDealorderGuid = dataItem.number;
            },
            getSameProductInfo(gruop) {
                axios.get(this.$httpPath + '/Trade/getSameGroupProduct/' + gruop)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data)
                            this.same_group_product_List = response.data;
                        }
                    })
                    .catch((error) => {

                    })
            },
            showChangeDealPopup(dataItem) {
                this.isChangeDealPopupVisible = true;
                // console.log(dataItem);
                this.changeDealInfo.orderGuid = dataItem.number;
                // console.log(this.changeDealInfo.orderGuid);
                this.changeDealInfo.sealProductName = dataItem.productName;
                this.changeDealInfo.sealProductGuid = dataItem.productId;
                axios.get(this.$httpPath + '/Trade/getProductInfo/' + this.changeDealInfo.sealProductGuid)
                    .then((response) => {
                        if (response.status === 200) {
                            // console.log(response.data.lStockNo)
                            this.changeDealInfo.sealProductNo = response.data.lStockNo;
                            this.getSameProductInfo(response.data.group)
                        }
                    })
                    .catch((error) => {

                    })
            },
            // Pudding Make 點擊視窗取消按鈕-->隱藏視窗
            cancelCloseDeal() {
                this.isCloseDealPopupVisible = false;
                this.closeDealorderGuid = '';
            },
            // Pudding Make 換倉
            switchDeal() {
                // console.log("----------------");
                console.log(this.changeDealInfo.orderGuid);
                this.switchMix.orderGuid = Encrypt(this.changeDealInfo.orderGuid.toString());
                // console.log(this.changeDealInfo.orderGuid);
                this.switchMix.switchProductGuid = Encrypt(this.changeDealInfo.buyProductGuid.toString());
                // console.log(this.changeDealInfo.buyProductGuid);
                this.switchMix.switchType = this.switchDealMarketPrice ? Encrypt((0).toString()) : Encrypt((1).toString());
                // console.log(this.switchDealMarketPrice ? 0 : 1); // 交易方式 0市價 1限價
                this.switchMix.switchPrice = Encrypt(this.switchDealLimitPrice.toString());
                // console.log(this.switchDealLimitPrice);
                const date = new Date();
                const utcString = date.toISOString();
                this.switchMix.switchTime = utcString;
                console.log(this.switchMix);

                if (this.changeDealInfo.buyProductGuid == '') {
                    alert("請選擇欲換倉之產品")
                }
                else {
                    axios.post(this.$httpPath + '/Trade/switchDeal', this.switchMix)
                        .then((response) => {
                            if (response.status === 200) {
                                this.isChangeDealPopupVisible = false;
                            }
                        })
                        .catch((error) => {
                            alert("換倉錯誤");
                            console.error("Request failed:", error);
                        });
                }
                this.switchMix.orderGuid = '';
                this.switchMix.switchProductGuid = '';
                this.changeDealorderGuid = '';
            },
            // 點擊視窗取消按鈕-->隱藏視窗
            cancelSwitchDeal() {
                this.switchMix.orderGuid = '';
                this.switchMix.switchProductGuid = '';
                this.isChangeDealPopupVisible = false;
                this.changeDealorderGuid = '';
            },
        }
    };
</script>
<style scoped>
    .table-scrollable {
        max-height: 400px;
        /* 這裡可以設定你想要的高度 */
        overflow-y: auto;
    }

    body {
        margin: 0;
        font-family: var(--bs-font-sans-serif);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .pdnav5 {
        padding: 0 11px;
        font-size: 16px;
        color: #828282;
        border-radius: 0 !important;
    }

    .dv-b {
        width: 100%;
        height: 100%;
        background-color: yellow;
    }

    .blue-border {
        border: 1px solid rgb(109, 236, 253);
    }

    .k-button .k-button-icon {
        background-color: transparent;
    }

    .k-grid td.k-state-selected,
    .k-grid tr.k-state-selected>td {
        background-color: rgba(255, 99, 88, 0.25) !important;
    }

    .k-header {
        color: #F0FFFF;
        background-color: rgba(255, 255, 255, 0.08) !important;
    }

    .k-grid-header,
    .k-header,
    th.k-header,
    .k-grid-header-wrap,
    .k-grouping-header,
    .k-grouping-header .k-group-indicator,
    .k-grid td,
    .k-grid-footer,
    .k-grid-footer-wrap,
    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked,
    .k-filter-row>td,
    .k-filter-row>th {
        color: #F0FFFF;
        border-color: rgba(255, 255, 255, 0.08);

    }

    #grid {
        /* chop the grid's height by 45px */
        height: calc(100%);
    }

    /* Pudding 平倉視窗 */
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .form-check {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .limit-price-label {
        margin-right: 10px;
        /* 添加右邊間距以區分標籤和輸入欄位 */
    }

    .form-control {
        width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .button-container {
        display: flex;
        justify-content: center;
        /* 在水平方向置中 */
        align-items: center;
        /* 在垂直方向置中 */
    }

    .custom-button {
        margin: 0 5px;
        /* 添加左右間距以在按鈕之間創建間隔 */
    }

    /* Pudding 平倉視窗 */

    @import 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';
    @import 'https://kendo.cdn.telerik.com/themes/4.41.2/default/default-main-dark.css';
</style>