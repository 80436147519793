<template>
    <div ref="griddiv">
        <Grid ref="grid_TAIFEX" id="grid_TAIFEX" :data-items="items" :selected-field="selectedField" :columns="columns"
            @rowclick="onRowClick">
            <!-- 以下為舊程式碼 | 20231119 | HENRY
               <template v-slot:myCellTemplate="{props}">
                      <td :style="cellBackground(props.dataItem)">
                              {{props.dataItem[props.field]}} 
                              <span v-if="props.dataItem.漲跌 < 0">
                                  <span className="k-icon k-i-sort-desc-sm" />
                              </span>
                              <span v-else>
                                  <span className="k-icon k-i-sort-asc-sm" />
                              </span>
                      </td>
              </template>    -->
            <!-- HENRY | 漲跌紅綠隨數值變動並新增箭頭符號 | 20231119 -->
            <template v-slot:myCellTemplate="{props}">
                <td :style="cellBackground(props.dataItem)">
                    {{props.dataItem[props.field]}}
                    <span v-if="props.dataItem.uintVolatility < 0">
                        <span class="k-icon k-i-sort-desc-sm" />
                    </span>
                    <span v-else>
                        <span class="k-icon k-i-sort-asc-sm" />
                    </span>
                </td>
            </template>
            <!-- HENRY | 最高價欄位長久為紅色 | 20231119 -->
            <template v-slot:myIntHighPrice="{props}">
                <td :style="myIntHighPriceBackground(props.dataItem)">
                    {{props.dataItem[props.field]}}
                </td>
            </template>
            <!-- HENRY | 最低價欄位長久為綠色 | 20231119 -->
            <template v-slot:myIntLowPrice="{props}">
                <td :style="myIntLowPriceBackground(props.dataItem)">
                    {{props.dataItem[props.field]}}
                </td>
            </template>
            <!-- Ricky 價格紅綠隨漲幅變動 -->
            <template v-slot:pricechangeTemplate="{ props }">
                <td class="text-center">
                    <div v-if="props.dataItem['nAmplitude'] < 0">
                        <font color="##82FF82">
                            {{ props.dataItem['nBid'] }}
                        </font>
                    </div>
                    <div v-else>
                        <font color="#E00000">
                            {{ props.dataItem['nBid'] }}
                        </font>
                    </div>
                </td>
            </template>
            <!-- Ricky 價格紅綠隨漲幅變動 -->
        </Grid>
    </div>
</template>



<script>
    import { Grid } from '@progress/kendo-vue-grid';
    import Stomp from 'stompjs'
    import axios from 'axios';
    import Vue from 'vue';
    import VueToast from 'vue-toast-notification';
    import Store from '../store/index.js'
    import Vuex from 'vuex';
    import { DropDownButton } from "@progress/kendo-vue-buttons";
    import { AutoComplete, ComboBox, DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
    import { Window } from '@progress/kendo-vue-dialogs';
    import { Button } from '@progress/kendo-vue-buttons';
    import { Input } from '@progress/kendo-vue-inputs';

    // import '../assets/css/telerik/default-main-dark.css'
    Vue.use(VueToast);


    export default {



        components: {
            Grid,
            // DropDownButton,
            // 'window': Window,
            // 'kbutton': Button,

        },
        data: function () {
            return {
                left: 300,
                top: 200,
                width: 500,
                height: 200,
                F: false,
                Winvisible: false,
                setting_items: [
                    "自訂商品",
                    "自訂欄位",
                    "字體大小",
                    "自訂風格",
                ],
                visible: true,
                //webSocketConnect
                // client:Stomp.client(Vue.prototype.$httpPath_ws),
                //webSocketConnect
                selectedField: 'selected',
                updatedData: [],
                editID: null,
                selectedID: null,
                group: [{ field: 'UnitsInStock' }],
                expandedItems: [],
                // columns: [
                //     { field: 'goodCode', title: '商品代號', hidden: true,width: '70%'},
                //     { field: 'goodName',cell: 'goodName', title: '商品', editable: false, height:'auto', width: '100%', locked: false, selectable: true, headerCell : 'head_goodName'},
                //     { field: 'cPosition_Many',cell: 'cPosition_Many', title: '倉位多', editable: false, height:'50%', width: '50%', locked: false, selectable: true, headerCell : 'head_cPosition_Many'},
                //     { field: 'cPosition_Null',cell: 'cPosition_Null', title: '倉位空', editable: false,  height:'50%',width: '50%', locked: false, selectable: true, headerCell : 'head_cPosition_Null'},
                //     { field: 'bid1', cell: 'bid1', title: '買進價',  editable: false,height:'50%', width: '77%', locked: false, selectable: true},
                //     { field: 'ask1', cell: 'ask1',title: '賣出價',  editable: false, width: '77%', locked: false, selectable: true},
                //     { field: 'tradingPrice',cell: 'tradingPrice', title: '成交價',  editable: false, width: '75%', locked: false},
                //     // { field: 'change',cell: 'change', title: '漲跌', editable: false, width: '60%', locked: false, selectable: true,headerCell :'head_tradingPrice'},
                //     // { field: 'change_Percent',cell: 'change_Percent', title: '漲幅%',  editable: false, width: '60%', locked: false, selectable: true, headerCell : 'head_change_Percent'},
                //     // { field: 'tradeQuantity',cell: 'tradeQuantity', title: '單量',  editable: false, width: '55px', locked: false, selectable: true, headerCell : 'head_tradeQuantity'},
                //     // { field: 'tradeVolume',cell: 'tradeVolume', title: '總量',  editable: false, width: '60px', locked: false, selectable: true, headerCell : 'head_tradeVolume'},
                //     // { field: 'yClosedPrice',cell: 'yClosedPrice', title: '昨收價',  editable: false, width: '70px', locked: false, selectable: true, headerCell : 'head_yClosedPrice'},
                //     // { field: 'openingPrice',cell: 'openingPrice', title: '開盤價',  editable: false, width: '80px', locked: false, selectable: true, headerCell : 'head_openingPrice'},
                //     // { field: 'highPrice',cell: 'highPrice', title: '最高價',  editable: false, width: '80px', locked: false, selectable: true, headerCell : 'head_highPrice'},
                //     // { field: 'lowPrice',cell: 'lowPrice', title: '最低價',  editable: false, width: '80%', locked: false, selectable: true, headerCell : 'head_lowPrice'},
                //     // { field: 'time',cell: 'time', title: '時間',  editable: false, width: '85%', locked: false, selectable: true, headerCell : 'head_time'},
                //     // { field: 'trade',cell: 'trade', title: '交易',  editable: false, width: '50%', locked: false, selectable: true, headerCell : 'head_trade'},
                //     // { field: 'closingPrice',cell: 'closingPrice', title: '最後成交價',  editable: false, width: '85%', locked: false, selectable: true, headerCell : 'head_closingPrice'},
                //     // { field: 'endDate',cell: 'endDate', title: '最後交易日',  editable: false, width: '85%', locked: false, selectable: true, headerCell : 'head_endDate'},
                //     // { field: 'cIllustrate',cell: 'cIllustrate', title: '說明',  editable: false, width: '60%', locked: false, selectable: true, headerCell : 'head_cIllustrate'},
                //     // { field: 'goodType',cell: 'goodType', title: '商品類別',  editable: false, width: '70%', locked: false, selectable: true, headerCell : 'head_goodType'}
                // ],
                // 元大系統：報價表訂閱結果
                columns: [
                    { field: 'marketCode', title: '市場別', editable: false, locked: true, selectable: true },
                    { field: 'productCode', title: '商品代號', editable: false, locked: true, selectable: true },
                    { field: 'productName', title: '商品', editable: false, width: '150px', locked: true, selectable: true },
                    { field: 'uintBidQty', title: '倉位多', editable: false, width: '120px' },
                    { field: 'uintAskQty', title: '倉位空', editable: false, width: '120px', format: '{0:d}' },
                    { field: 'intBuyPrice', title: '買進價', width: '150px', cell: "myCellTemplate" },
                    { field: 'intSellPrice', title: '賣出價', width: '150px', cell: "myCellTemplate" },
                    { field: 'intDealPrice', title: '成交價', width: '150px', cell: "myCellTemplate" },
                    { field: 'uintVolatility', title: '漲跌', width: '150px', cell: "myCellTemplate" },
                    { field: 'nUintVolatility', title: '漲幅%', width: '150px', cell: "myCellTemplate" },
                    { field: 'uintVol', title: '單量', editable: false, width: '150px' },
                    { field: 'uintTotalVol', title: '總量', editable: false, width: '150px' },
                    { field: 'intYstPrice', title: '昨收價', editable: false, width: '150px' },
                    { field: 'intOpenPrice', title: '開盤價', editable: false, width: '150px' },
                    { field: 'intHighPrice', title: '最高價', width: '150px', cell: "myIntHighPrice" },
                    { field: 'intLowPrice', title: '最低價', width: '150px', cell: "myIntLowPrice" },
                    { field: 'struTime', title: '時間', editable: false, width: '150px' },
                    { field: '交易', title: '交易', editable: false, width: '150px' },
                    { field: '最後成交價', title: '最後成交價', editable: false, width: '150px' },
                    { field: '最後交易日', title: '最後交易日', editable: false, width: '150px' },
                    { field: '說明', title: '說明', editable: false, width: '150px' },
                    { field: '商品類別', title: '商品類別', editable: false, width: '150px' }
                ],
                prods: [ //測試資料
                    {
                        goodCode: "goodCode",
                        goodName: 'TX   ',
                        cPosition_Many: "111",
                        cPosition_Null: "222",
                        bid1: "333",
                        ask1: "444",
                        tradingPrice: "555",
                        change: "666",
                        change_Percent: "-7%",
                        tradeQuantity: "888",
                        tradeVolume: "999",
                        yClosedPrice: "0000",
                        openingPrice: "1411100",
                        highPrice: "442224",
                        lowPrice: "533355"
                    },
                    {
                        goodCode: "goodCode",
                        goodName: 'TX   ',
                        cPosition_Many: "999",
                        cPosition_Null: "",
                        bid1: "555",
                        ask1: "555",
                        tradingPrice: "555",
                        change: "555",
                        change_Percent: "5%",
                        tradeQuantity: "2",
                        tradeVolume: "55",
                        yClosedPrice: "55",
                        openingPrice: "1400",
                        highPrice: "444",
                        lowPrice: "555"
                    },
                ],
                gridData: [],
                products: [],
            };
        },
        //webSocketConnect
        created() {

        },
        //webSocketConnect
        computed: {
            /*以下為舊程式碼 | 20231119 | HENRY
            items() {
                return this.products.map((item) => ({
                    ...item,
                    selected: item.productCode === this.selectedID,
                }));
            },*/
            // | 20231119 | HENRY | 商品欄位數據呈現格式調整
            items() {
                return this.products.map((item) => {
                    const intBuyPrice = item.intBuyPrice;
                    const intSellPrice = item.intSellPrice;
                    const intDealPrice = item.intDealPrice;
                    const uintVolatility = item.uintVolatility;
                    const intYstPrice = item.intYstPrice;
                    const intOpenPrice = item.intOpenPrice;
                    const intHighPrice = item.intHighPrice;
                    const intLowPrice = item.intLowPrice;

                    return {
                        ...item,
                        selected: item.productCode === this.selectedID,
                        intBuyPrice,
                        intSellPrice,
                        intDealPrice,
                        uintVolatility,
                        intYstPrice,
                        intOpenPrice,
                        intHighPrice,
                        intLowPrice,
                    };
                });
            },
            allProducts() {
                return Store.state.allProducts;
            },
            real_time_product() {
                return Store.state.real_time_product;
            },

            nowSelectProduct() {
                return Store.state.nowSelectProduct.bstrStock;
            }
        },
        watch: {
            allProducts() {
                //this.products = Store.state.allProducts;
                // console.log(this.products)
            },
            real_time_product() {
                this.products = Store.state.real_time_product;
                // console.log("this.products",this.products);
                // console.log("this.products.length",this.products.length);
                this.products = this.products.length < 1 ? this.products : this.products.filter(item => item.marketCode === "TAIFEX");
                // this.products = Store.state.real_time_product;
                // console.log("this.products")
                // console.log(this.products)
                // console.log("this.products")
            },
            // nowSelectProduct() {
            //     this.selectedID = Store.state.nowSelectProduct.bstrStock;
            // }
        },
        methods: {
            //|20231228|根據使用者調整的寬度調整ＧＲＩＤ的大小|RICKY|
            resizeGrid(width, height) {
                console.log("resizeGridTAIFEX")
                let vm = this;
                document.getElementById('grid_TAIFEX').style.width = width - 10 + 'px';
                document.getElementById('grid_TAIFEX').style.height = height - 80 + 'px';
                console.log(document.getElementById('grid_TAIFEX').style.width)
            },
            handleMove(event) {
                this.left = event.left;
                this.top = event.top;
            },
            handleResize(event) {
                this.left = event.left;
                this.top = event.top;
                this.width = event.width;
                this.height = event.height;
            },

            onRowClick(event) {
                this.products = Store.state.real_time_product;
                this.selectedID = event.dataItem.productCode;
                console.log(event.dataItem.productName);
                this.loadNowSelectProduct(event.dataItem);
                /*var vm = this;
                this.productsRealTime.forEach(function(e){
                    if(e.bstrStock==event.target.value){
                        console.log(e.bstrStockNo);
                        vm.loadNowSelectProduct(e);
                        console.log(vm.nowSelectProductName);
                    }
                });*/
            },
            loadNowSelectProduct(data) {
                console.log("Store.dispatch( LoadNowSelectProduct ");
                return Store.dispatch("LoadNowSelectProduct", data);
            },
            resizeLT: function (width, height) {
                this.$refs.griddiv.style.height = height - 100 + "px";
            },
            /* 以下為舊程式碼 | 20231119 | HENRY
            cellBackground: function(dataItem){
                const examplePrice = dataItem.cell < dataItem.開盤價;
                return {
                    "background-color": examplePrice 
                        ? "rgb(55, 180, 0, 0.32) !important" 
                        : "rgb(243, 23, 0,0.32)  !important"};
            },*/
            // 20231119 | HENRY | 漲跌紅綠隨數值變動       
            cellBackground: function (dataItem) {
                const examplePrice = dataItem.intDealPrice < dataItem.intYstPrice;
                //console.log("dataItem: ", dataItem);
                return {
                    "background-color": examplePrice
                        ? "rgb(55, 180, 0, 0.32) !important"
                        : "rgb(243, 23, 0,0.32)  !important"
                };
            },
            // 20231119 | HENRY | 漲跌紅綠隨數值變動       
            myIntHighPriceBackground: function (dataItem) {
                return {
                    "background-color": "rgb(243, 23, 0,0.32)  !important"
                };
            },
            // 20231119 | HENRY | 漲跌紅綠隨數值變動       
            myIntLowPriceBackground: function (dataItem) {
                return {
                    "background-color": "rgb(55, 180, 0, 0.32)  !important"
                };
            },
            rowClick: function (e) {
                //       var grid = e.sender;
                //       var cellIndex = grid.;
                // console.log(cellIndex);
                //e.dataItem.inEdit = true;
            },
            closeEdit(e) {
                if (e.target === e.currentTarget) {
                    this.editID = null;
                }
            },
            addRecord() {
                const newRecord = { ProductID: this.products.length + 1 };
                const data = this.products.slice();
                data.unshift(newRecord);
                this.products = data;
                this.editID = newRecord.ProductID;
            },
            toggleDialog() {
                this.visible = !this.visible;
            },
            WintoggleDialog() {
                this.Winvisible = !this.Winvisible;
            },

        },
        mounted() {
            this.products = Store.getters.real_time_product
            //console.log(this.products)
            //this.refreshGrid();
            //this.createRandomData();
        },
    };

</script>
<style>
    .blue-border {
        border: 1px solid rgb(109, 236, 253);
    }

    .k-button .k-button-icon {
        background-color: transparent;
    }

    .k-grid td.k-state-selected,
    .k-grid tr.k-state-selected>td {
        background-color: rgba(255, 99, 88, 0.25) !important;
    }

    .k-header {
        color: #F0FFFF;
        background-color: rgba(255, 255, 255, 0.08) !important;
    }

    .k-grid-header,
    .k-header,
    th.k-header,
    .k-grid-header-wrap,
    .k-grouping-header,
    .k-grouping-header .k-group-indicator,
    .k-grid td,
    .k-grid-footer,
    .k-grid-footer-wrap,
    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked,
    .k-filter-row>td,
    .k-filter-row>th {
        color: #F0FFFF;
        border-color: rgba(255, 255, 255, 0.08);

    }

    #grid {
        /* chop the grid's height by 45px */
        height: calc(100%);
    }

    @import 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';
    @import 'https://kendo.cdn.telerik.com/themes/4.41.2/default/default-main-dark.css';
</style>